<template>
    <div class="faq-item" @click='toggleOpen' v-bind:class="{'highlight': highlight}">
        <h1>{{ title }}</h1>
        <div v-if="open" class="faq-content" v-html="content"></div>
    </div>
</template>

<script>
export default {
    name: 'faq-item',
    data() {
        return {
            open: false,
            highlight: false,
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open;
        },
        toggleHighlight() {
            this.highlight = !this.highlight;
            this.open = true;
        }
    }
}
</script>

<style lang="scss">
.faq-item {
    /* Add your custom styles here */
    cursor: pointer;

    border-radius: 12px;
    border: 1px solid $emphasis;
    padding: 0 12px;
    width: 100%;

    color: #EDD0C5;
    text-align: left;

    h1 {
        font-size: 20px;
        font-weight: 400;
        &::before {
            content: '+ ';
            padding: 0 6px;
        }
    }

    .faq-content{
        p {
            cursor: default;
            font-size: 16px;
            padding: 4px;
        }
    }

}
.highlight {
    background-color: #EDD0C5;
    color: #2E2E2E;
}
</style>
