<template>
  <div>
    <div class="top-bar" v-bind:class="{'display-bar': navToggled}">
      <div class="news-bar" v-if="!showCart">
        <a href="/stock-reports/">Introducing Stock Reports for all our retailers!</a>
      </div>
      <div class="nav-bar">
        <bar-cart v-if="showCart">
        </bar-cart>
        <div v-else id="empty"></div>
        <img v-if="!showCart && !navToggled" src="../assets/logo.svg" id="icon">
        <router-link v-if="!showCart && !navToggled" to="/" tag="h1" id="word-logo">
          Bottlebeagle
        </router-link>
        <ul class="nav" v-bind:class="{'display-nav': navToggled}">
          <li>
            <a href="stock-reports/">Bottles</a>
          </li>
          <li>
            <router-link v-if="isLoggedIn" to="/dashboard">Dashboard</router-link>
            <a v-else @click.prevent.stop="loginPressed" href="">Login</a>
          </li>
        </ul>
        <button class="collapser" @click="navToggled = !navToggled" v-bind:class="{'toggled-button': navToggled}">
          <span class="navbar-collapser"></span>
        </button>
        <login-modal v-if="showLoginModal" @didAuthenticate="authenticatedFromModal" @dismiss="dismissLoginModal"></login-modal>
      </div>
    </div>
    <div class="top-bar alert-banner" v-if="!dismissedWarning && isDelinquent">
      <h3>Your account is overdue. Pay now to avoid interruption to your alerts.</h3>
      <router-link :to="{name: 'dashboard', params: {scrollTo: 'billing'}}" v-on:click.native="dismissedWarning=true" tag="button" class="big-button">Pay now</router-link>
    </div>
  </div>
</template>

<script>
import LoginModal from '@/components/login-modal.vue';
import BarCart from '@/components/CartComponent.vue';

export default {
  name: 'app-nav',
  props: ['user'],
  components: {LoginModal, BarCart},
  data() {
    return {
      showLoginModal: false,
      showSpinner: false,
      dismissedWarning: false,
      navToggled: false,
    }
  },
  created() {
    this.$store.dispatch('cart/loadCart');
  },
  methods: {
    loginPressed: function() {
      console.log("Show login modal");
      this.showLoginModal = true;
    },
    dismissLoginModal: function () {
      this.showLoginModal = false;
    },
    authenticatedFromModal: function (newUser) {
      console.log("Logged in");
      this.showLoginModal = false;
      this.$router.push('dashboard');
    },
  },
  computed: {
    showCart: function() {
      if (this.$route.name == 'home' && !this.$store.getters['cart/emptyCart']) {
        return true;
      }
      return false;
    },
    cartItems: function() {
      return this.$store.getters['cart/cartItems'];
    },
    isLoggedIn: function() {
      return this.$store.state.token != "";
    },
    isDelinquent: function() {
      let delinquency = this.$store.state.stripeAccount.delinquent ? this.$store.state.stripeAccount.delinquent : false;
      return delinquency;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

div {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  -webkit-font-smoothing: auto;

  .top-bar {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    z-index: 100;
    height: 120px;
    flex-direction: column;

    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  }
  
  .news-bar {
    background: #FF682C;
    a {
      color: white;
    }
    padding: 0.2em;
  }

  .nav-bar {
    background: $nav-background;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 1001;

    .display-nav {
      display: flex;
      width: 100%;
    }

    #empty {
      margin: 0;
      flex: 1;
    }

    #icon {
      width: 40px;
      height: 40px;
      box-shadow: 2px 2px white;
      border-radius: 4px;
      margin-left: 5px;
    }

    #word-logo {
      font-family: $header-font;
      letter-spacing: -2px;
      color: white;
      text-shadow: 2px 2px $emphasis;
      &.router-link-active {
        cursor: pointer;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    h1 {
      margin: auto 1em auto 0.4em;
      color: $emphasis;
      text-align: center;
      font-family: "Montagu Slab";
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 66.667% */
      letter-spacing: -1.8px;    
    }
    h3 {
      margin: auto;
    }
    ul {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: white;
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-color: $emphasis;
      }
    }
    button {   // the cute lil hamburger
      color: hsla(0,0%,100%,.5);
      border-radius: 0.25em;
      border: none;
      border-color: hsla(0,0%,100%,.1);
      height: 3em;
      width: 4em;
      margin: auto 0.5em;
      background: none;
      display: none;
      span {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        font-family: inherit;
        text-transform: none;
        font-size: 1.25rem;
        line-height: 1;
        cursor: pointer;
        color: hsla(0,0%,100%,.5);
        box-sizing: border-box;
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat 50%;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    }
  }


  .alert-banner {
    background: rgb(129, 22, 22);
    top: 60px;
    justify-content: space-between;
    text-align: left;

    #word-logo {
      font-family: $header-font;
      letter-spacing: -2px;
      &.router-link-active {
        cursor: pointer;
      }
    }

    h1 {
      margin: auto 2em;
      color: $emphasis;
      max-width: 80%;
    }
    h3 {
      color: white;
      margin: auto 0.2em;
    }

    .big-button {
      background: #d9ff00;
      width: 100%;
      max-width: 8em;
      margin: 0.2em 1em;
      font-family: AvenirNext-BoldItalic;
      font-size: 26px;
      color: black;
      border-radius: 30px;
      border-style: none;
      outline: none;
      cursor: pointer;

      &:hover {
        background: #00818D;
        transition:all 0.3s ease;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  div {
    .display-bar {
      height: unset !important;
    }
    .top-bar {
      min-height: 60px;
      height: 80px;
    }
    .nav-bar {
      #empty {
        display: none;
      }
      #word-logo {
        // position: absolute;
        // top: 63px;
        min-width: 256px;
        margin: 0 0.5em;

      }
      button {
        display: block;
        margin-left: auto;
      }

      .toggled-button {
        margin-bottom: 0.5em;
      }

      .nav {
        display: none;
        flex-direction: column;

        text-align: right;
        min-width: 200px;
        height: calc(100% + 0px);
        margin-bottom: 3em;
        li {
          width: calc(100% - 15px);
          padding: 0.5rem 0;
        }
      }

      .display-nav {
       display: flex;

      }
    }
  }
}
@media only screen and (max-width: 650px) {
  div {
    .nav-bar {
      display: flex;
      flex-wrap: wrap;
      #word-logo {
        margin: 5px auto 0 auto;
        font-size: 40px;
      }
      .nav {
        min-width: 300px;
      }

    }
    .alert-banner {
      padding: 0.2em;
      h3 {
        font-size: 14px;
      }

      .big-button {
        background: #d9ff00;
        width: 100%;
        max-width: 30%;
        margin: 0.2em 0.2em;
        font-family: AvenirNext-BoldItalic;
        font-size: 20px;
        border-radius: 30px;
        color: black;
        border-style: none;
        outline: none;
        cursor: pointer;

        &:hover {
          background: #00818D;
          transition:all 0.3s ease;
        }
      }

    }

  }

}

@media only screen and (max-width: 400px) {
  div {
      .nav-bar {
        display: flex;
        flex-wrap: wrap;
        #word-logo {
          margin: 0 10px;
          font-size: 30px;
          min-width: 0;
        }
        #icon {
          width: 30px;
          height: 30px;
        }
        .nav {
          min-width: 300px;
        }

      }
    }
  }

</style>
