<template>
    <div id="bar-cart">
      <div v-for="item in latestCartItems" :key="uniqueIdForCartProduct(item)" ref="uniqueIdForCartProduct(item)" class="bar-item" @mouseenter="showX(item)" @mouseleave="hideX()">
        <div class="item-pic">
          <img :src="imageUrl(item.image_url)"/>
        </div>
        <button :class="{'show-x': uniqueIdForCartProduct(item) == xToShow}" class="remove-item" @click="removeFromCart(xToShow)">X</button>
      </div>
      <div id="cart-overflow">
        <h3 v-if="cartItems.length > 4 && !xToShow">+ {{ cartItems.length - 4 }} more</h3>
        <h3 v-else-if="xToShow">{{ hoveredProductName }}</h3>
      </div>
    </div>
  </template>
  
  <script>
import helperMixin from '@/mixins/helperMixin';
  export default {
    name: 'bar-cart',
    mixins: [helperMixin],
    data() {
      return {
        xToShow: null,
      }
    },
    computed: {
      uniqueIdsForCartProducts() {
        return this.cartItems.map(item => this.uniqueIdForCartProduct(item));
      },
      latestCartItems() {
        // return the latest 5 items in the cart:
        return this.cartItems.slice(-4);
      },
      cartItems() {
        return this.$store.getters['cart/cartItems'];
      },
      hoveredProductName() {
        if (this.xToShow) {
          let item = this.cartItems.find(item => this.uniqueIdForCartProduct(item) == this.xToShow);
          if (item) {
            return item.name;
          }
        }
        return "";
      }
    },
    methods: {
      removeFromCart(productUniqueId) {
        // get the actual product:
        let product = this.cartItems.find(item => item.uniqueId == productUniqueId);
        if (this.$store.getters['cart/isProductInCart'](product)) {
          this.$store.dispatch('cart/removeFromCart', product);
        }
      },
      imageUrl(url) {
        if (url) {
          if (url.startsWith("http")) {
            return url;
          }
          return process.env.VUE_APP_MEDIA_BASE_URL + url;
        } else {
          return process.env.VUE_APP_MEDIA_BASE_URL + "products/thumb.png";
        }
      },
      showX(item) {
        this.xToShow = this.uniqueIdForCartProduct(item);
      },
      hideX() {
        this.xToShow = null;
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  #bar-cart {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .bar-item {
      height: 100%;
      padding: 0 0.2em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .show-x {
        color: white !important;
        background: $emphasis !important;
        cursor: pointer !important;
      }

      .remove-item {
        position: relative;
        left: -10px;
        top: -30px;
        height: 25px;
        width: 25px;
        border: 0;
        border-radius: 25px;

        color: transparent;
        background: transparent;
        cursor: default;
      }
      .item-pic {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        border-radius: 8px;
        background: white;

        img {
          padding: 2px 0;
          height: 60px;
          width: 35px;
          // width: 80px;
          // min-width: 80px;
          max-width: 35px;
          object-fit: cover;
          border-radius: 8px;
        }

      }

    }

    #cart-overflow {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: left;
      h3 {
        color: white;
        margin: 0;
      }
    }
    // .bar-item {
    //   width: 50px;
    //   height: 50px;
    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //   }
    // }
  }
  </style>