import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { STEPS, getNextStep } from './steps';
import helperMixin from '@/mixins/helperMixin';
import { allCountries } from 'country-region-data';

Vue.use(Vuex)

const regionModule = {
  namespaced: true,
  state: {
    regions: [],
    selectedRegion: null,
  },
  mutations: {
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_SELECTED_REGION(state, region) {
      console.log("Setting selected region in mutation: ");
      console.log(region);
      state.selectedRegion = region;
    },
  },
  actions: {
    initializeRegions({ commit }) {
      var regions = allCountries.filter(country => (["CA", "US"].includes(country[1]))) // filter by valid countries
        .map(country => country[2].map(region=>region.concat([country[0], country[1]]))) // make list of region lists, plus country data
        .flat(1) // reduce lists into one big long list
        .filter(region => (["ON", "MB", "BC", "QC", "FL", "NS"].includes(region[1]))) // remove Nunavut
        .map(e => ({region: e[1], name: e[0], country: e[3], countryName: e[2]})) // make objects that are coherent  
      commit('SET_REGIONS', regions);
    },
    setSelectedRegion({ commit }, region) {
      commit('SET_SELECTED_REGION', region);
    },
  },
  getters: {
    regions: (state) => state.regions,
    selectedRegion: (state) => state.selectedRegion,
    findRegionForGeoRegionInfo: (state) => (regionInfo) => {
      if (!regionInfo) return null;
  
      let region;
      const countryCode = regionInfo.countryCode;
  
      if (regionInfo.codes) {
        const firstLevelCode = regionInfo.codes.find(
          (code) => parseInt(code.level) === 1 && code.type === 'ISO3166-2'
        );
        if (firstLevelCode) {
          region = state.regions.find(
            (r) => r.region === firstLevelCode.code && r.country === countryCode
          );
        }
  
        if (!region) {
          let regionLevel = 3;
          let regionCode = '';
  
          while (regionLevel > 0) {
            if (regionInfo.hasOwnProperty(`adminCode${regionLevel}`)) {
              regionCode = regionInfo[`adminCode${regionLevel}`];
              region = state.regions.find(
                (r) => r.region === regionCode && r.country === countryCode
              );
              if (region) break;
              regionLevel -= 1;
            } else {
              regionLevel -= 1;
            }
          }
        }
      }
  
      return region;
    },
  }
}

const cartModule = {
  namespaced: true,
  state: {
    cart: []
  },
  mutations: {
    ADD_TO_CART(state, product) {
      // limit cart to 100 products
      if (state.cart.length >= 100) {
        return false;
      }
      let uniqueId = helperMixin.methods.uniqueIdForCartProduct(product);
      product.uniqueId = uniqueId;
      
      if (!state.cart.find(item => item.uniqueId === uniqueId)) {
        state.cart.push(product);
      }
    },
    REMOVE_FROM_CART(state, productUniqueId) {
      state.cart = state.cart.filter(item => item.uniqueId !== productUniqueId);
    },
    CLEAR_CART(state) {
      state.cart = [];
      // also clear the local storage
      localStorage.removeItem('cart');
    },
    LOAD_CART(state, cart) {
      state.cart = cart;
    },
    UPDATE_CART_ITEM_STATUS(state, newStatuses) {
      // loop over each new status, find the cart item for it, and merge them:
      for (let newStatus of newStatuses) {
        // if bbid...
        var item = null;
        if (newStatus.bbid) {
          var item = state.cart.find(item => String(item.bbid) === String(newStatus.bbid));
          if (item) {
            item.cartStatus = newStatus.stock_status;
            item.existingAlert = newStatus.existing_alerts || false;
            item.lastStock = newStatus.last_in_stock;
            item.allocated = newStatus.allocated;
          }
        } else {
          var item = state.cart.find(item => String(item.item_id) === String(newStatus.item_id) && item.retailer_id === newStatus.retailer_id);
        }
        if (item) {
          item.cartStatus = newStatus.stock_status;
          item.lastStock = newStatus.last_in_stock;
        } else {
          console.log("Couldn't find item for status update: " + newStatus.item_id);
        }
      }
    }
  },
  actions: {
    addToCart({ commit }, product) {
      commit('ADD_TO_CART', product);
      this._vm.$posthog.capture('product_added_to_cart', {'product': product});
    },
    removeFromCart({ commit }, productId) {
      // if it's a string try it as a unique ID, otherwise generate a unique id from the object
      if (typeof productId !== 'string') {
        // check if it has a unique ID
        if (productId.uniqueId) {
          commit('REMOVE_FROM_CART', productId.uniqueId);
        } else {
          commit('REMOVE_FROM_CART', helperMixin.methods.uniqueIdForCartProduct(productId));          
        }

      } else {
        commit('REMOVE_FROM_CART', helperMixin.methods.uniqueIdForCartProduct(productId));
      }
    },
    clearCart({ commit }) {
      commit('CLEAR_CART');
    },
    loadCart({ commit }) {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      commit('LOAD_CART', cart);
    },
    updateCartStatus({ commit }, newStatuses) {
      commit('UPDATE_CART_ITEM_STATUS', newStatuses);
    }
  },
  getters: {
    emptyCart: state => state.cart.length === 0,
    cartItems: state => state.cart,
    isProductInCart: (state) => (cartProduct) => {
      // just check for the unique ID
      let uniqueId = helperMixin.methods.uniqueIdForCartProduct(cartProduct);
      let result = state.cart.find(item => item.uniqueId === uniqueId);
      if (result) {
        return true;
      }
      return false;
    },
    statusesInCart: (state) => {
      // return key value pairs of stock_status: cart objects with that status"
      let statuses = {};
      for (let item of state.cart) {
        if (statuses[item.cartStatus]) {
          statuses[item.cartStatus].push(item);
        } else {
          statuses[item.cartStatus] = [item];
        }
      }
      return statuses;
    }
  }
};


const store = new Vuex.Store({
  modules: {
    cart: cartModule,
    regions: regionModule,
  },
  state: {
    token: '',
    phoneNumber: '',
    stripeAccount: {},
    alerts: [],
    email: '',
    subscribedToNewsletter: '',
    showedAgeModal: false,
    step: STEPS[0],
  },
  mutations: {
    AUTH_USER (state, userData) {
      console.log("Auth user mutation");
      state.token = userData.token;
      state.stripeAccount = userData.stripe_account;
      state.phoneNumber = userData.phone_number;
      state.alerts = userData.alerts_for_user;
      state.email = userData.email;
      state.subscribedToNewsletter = userData.subscribed_to_newsletter;
    },
    LOGOUT_USER (state) {
      console.log("Logout user mutation");
      state.token = '';
      state.phoneNumber = '';
      state.stripeAccount = {};
      state.alerts = [];
      state.email = '';
      state.subscribedToNewsletter = '';
      // localStorage.removeItem('cart');
      // state.cart = [];
      // state.step = STEPS[0];
    },
    GET_USER (state, userData) {
      console.log("get user mutation");
      state.stripeAccount = userData.stripe_account;
      state.phoneNumber = userData.phone_number;
      state.alerts = userData.alerts_for_user;
      state.email = userData.email;
      state.subscribedToNewsletter = userData.subscribed_to_newsletter;
    },
    GET_ALERTS (state, alertData) {
      state.alerts = alertData;
    },
    PAUSE_ALERT (state, alertData) {
      state.alerts = alertData;
    },
    TOGGLE_NEWSLETTER (state, newsletterData) {
      state.subscribedToNewsletter = newsletterData;
    },
    DELETE_ALERT (state, alertData) {
      state.alerts = alertData;
    },
    DELETE_PAYMENT_METHOD (state) {
      state.stripeAccount.payment_method = null;
    },
    SET_TOKEN (state, token) {
      state.token = token;
    },
    SET_SUBSCRIPTION (state, subscription) {
      // takes the dict from CreateSubscriptionSerializer and saves it
      state.stripeAccount.subscription = subscription;
    },
    SHOWED_AGE_MODAL (state) {
      state.showedAgeModal = true;
    },
    INCREMENT_STEP (state) {
      state.step = STEPS[state.step.stepNumber + 1];
      console.log("Increment step mutation: " + state.step.stepName + " " + state.step.stepNumber);
    },
    SET_STEP (state, step) {
      state.step = step;
      console.log("Set step mutation: " + state.step.stepName + " " + state.step.stepNumber);
    }
  },
  actions: {
    setStep ({commit}, step) {
      // if step is an integer then get the object:
      if (typeof step === 'number') {
        step = STEPS[step];
      }
      commit('SET_STEP', step);
    },
    incrementStep ({commit}) {
      commit('INCREMENT_STEP');
    },
    resetStep ({commit}) {
      commit('SET_STEP', STEPS[0]);
    },
    login ({ commit, store }, authData) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {

          var data = {
            phone_number: authData.phoneNumber,
            code: authData.code,
          }
          if (authData.email) {
            data.email = authData.email;
          }
          axios.post('verify_number/code/', data, {withCredentials: true, transformRequest: [(data, headers) => {
            if (headers.common) {
              delete headers.common.Authorization;
            }
            return data;
        },...axios.defaults.transformRequest]},
          ).then(response => {
            commit('AUTH_USER', response.data)
            // save token
            localStorage.setItem('token', this.state.token)

            // set token on axios
            axios.defaults.headers = {'Authorization': 'Token ' + this.state.token};
            this._vm.$posthog.identify(response.data.phone_number);
            resolve(response)
          }).catch((error) => {
            console.log(error);
            reject(error)
          })

        }, 1000)
      })
    },
    fullLogout ({commit}) {
      commit('LOGOUT_USER');
      // set token on axios
      delete axios.defaults.headers["Authorization"]
      localStorage.removeItem('token');
      // also clear the cart
      commit('cart/CLEAR_CART');
      this._vm.$posthog.reset();

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log("Yurrr login action");
          axios.get('logout', {withCredentials: true}
          ).then(response => {
            console.log("Logged out babyyy")
            resolve(response)
          }).catch((error) => {
            console.log(error);
            reject(error)
          })
        }, 1000)
      })
    },
    logout ({ commit }) {
      commit('LOGOUT_USER');
      // set token on axios
      delete axios.defaults.headers["Authorization"]
      localStorage.removeItem('token');
    },
    getUser ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          axios.get('current_user/').then((response) => {
            // add user data to vuex
            commit('GET_USER', response.data)
            this._vm.$posthog.identify(response.data.phone_number);
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            if (error.request.status === 403) {
              dispatch('logout');
            }
            reject(error);
          })
        }, 1000)
      })
    },
    getAlerts ({ commit }) {
      return new Promise((resolve, reject) => {
        console.log("Get user action");
        setTimeout(() => {
          axios.get('current_user/alerts/').then((response) => {
            // add user data to vuex
            commit('GET_ALERTS', response.data);
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    pauseAlert ({ commit }, alert) {
      return new Promise((resolve, reject) => {
        console.log("Pause alert action");
        setTimeout(() => {
          axios.get('current_user/toggle_alert/' + alert.id + '/').then((response) => {
            console.log("Alert " + alert.id + " paused");

            // add user data to vuex
            commit('PAUSE_ALERT', response.data);
            resolve(response);
          }).catch(function (error) {
            reject(error);
          })
        }, 1000)
      })
    },
    toggleNewsletterSubscription ({ commit }) {
      return new Promise((resolve, reject) => {
        console.log("Newsletter toggle action");
        setTimeout(() => {
          axios.get('current_user/toggle_newsletter/').then((response) => {
            // add user data to vuex
            console.log("Response to toggle: " + response.data);
            commit('TOGGLE_NEWSLETTER', response.data);
            resolve(response);
          }).catch(function (error) {
            reject(error);
          })
        }, 1000)
      })
    },
    deleteAlert ({ commit }, alert) {
      return new Promise((resolve, reject) => {
        console.log("Delete alert action");
        setTimeout(() => {
          axios.delete('current_user/alerts/' + alert.id + '/').then((response) => {
            console.log("Alert " + alert.id + " deleted");

            // add user data to vuex
            commit('DELETE_ALERT', response.data);
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    deletePaymentMethod ({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        console.log("Delete payment method action");
        setTimeout(() => {
          axios.delete('current_user/payment_methods/' + paymentMethodId +'/').then((response) => {
            console.log("Deleted PM store returned");
            commit('DELETE_PAYMENT_METHOD');
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          })
        }, 1000)
      })
    },
    softDeletePaymentMethod({commit}) {
      commit('DELETE_PAYMENT_METHOD');
    },
    addPaymentMethod ({ commit }, { customerId, paymentMethodId, email }) {
      return new Promise((resolve, reject) => {
        console.log("Add payment method action");
        setTimeout(() => {
          axios.post('current_user/payment_methods/', {
            payment_method: paymentMethodId,
            email: email,
          }).then((result) => {

            commit('GET_USER', result.data);
    
            resolve(result);
          }).catch(function (error) {
            console.log(error);
            reject(error);
          });
        }, 1000)
      })
    },
    setToken ({ commit }, token) {
      console.log("Set token action");
      commit('SET_TOKEN', token);
    },
    setSubscription ({commit}, subscription) {
      commit('SET_SUBSCRIPTION', subscription);
    },
    showedAgeModal ({commit}) {
      commit('SHOWED_AGE_MODAL');
      this._vm.$posthog.capture('age_modal_hidden');
    }
  },
  getters: {
    isAuthenticated: state => state.token !== '',
    token: state => state.token,
    phoneNumber: state => state.phoneNumber,
    stripeAccount: state => state.stripeAccount,
    alerts: state => state.alerts,
    email: state => state.email,
    subscribedToNewsletter: state => state.subscribedToNewsletter,
    showedAgeModal: state => state.showedAgeModal,
    step: state => state.step,
    isProductInAlerts: (state) => (product) => {
      if (state.alerts === undefined) {
        console.log("Alerts not loaded yet");
        return false;
      }
      // check for bbid first
      if (product.bbid) {
        return state.alerts.find(alert => alert.product.bbid == product.bbid)
      } else if (product.item_id) {
        return state.alerts.find(alert => alert.product.item_id == product.item_id)
      }
    },
  }
});

// Persist the cart to local storage
store.subscribe((mutation, state) => {
  if (mutation.type.startsWith('cart/')) {
    // save the cart to local storage
    localStorage.setItem('cart', JSON.stringify(state.cart.cart));

    // send the cart to the server
    // TODO: implement
    if (state.cart.cart.length === 0) {
      return;
    }

    // axios.post('cart/', state.cart.cart).then((response) => {
    //   console.log("Cart sent to server");
    // });
  }
});

export default store;