<template lang="html">
  <div class="modal-container">
    <div class="confirm-modal modal">
      <h1>Are you of age?</h1>
      <label>
        We have to check :)</label>
        <input type="date" v-model='date' data-hj-allow>
      <div class="buttons">
        <button class="big-button" @click="gentleButtonPressed">I am!</button>
      </div>
    </div>
    <div class="modal-backdrop" @click="gentleButtonPressed"></div>
  </div>
</template>

<script>

export default {
  name: 'AgeModal',
  data: function () {
    return {
      date: "1980-01-01",
    }
  },
  methods: {
    gentleButtonPressed () {
      this.$emit('ageSet', this.date);
    },
    destructiveButtonPressed () {
      this.$emit('noAge');
    },
  },
}
</script>

<style lang="scss" scoped>

.modal-backdrop {

}

.confirm-modal {
  background: $card-background;
  p {
    margin: 1em auto;
    max-width: 464px;
    min-width: 17em;

  }
}


</style>
