<template>
  <div class="component card" id="checkout">
    <div class="title" v-if='shouldUpgrade && upgradeInfo != false'>
      <h1>You're out of alerts</h1>
      <div>
        <p>You've used up all your alerts in your {{subscription.plan_info.name}} plan. To add this alert, you can upgrade now to the next tier, or pause one of your alerts and checkout again.</p>
        <p>To upgrade to {{upgradeInfo.next_tier.name}}, you'll be billed a prorated difference of {{ prorationPrice }} now, and will continue to be billed {{ nextTierPrice }} (including tax) on a {{ upgradeInfo.next_tier.billing_period }} basis.</p>
      </div>
      <button class="big-button" @click="upgradeSubscriptionCheckout">Upgrade to {{ upgradeInfo.next_tier.name }} to start your alert</button>
    </div>
    <div class="title" v-else-if="hasActiveSubscription && !shouldUpgrade">
      <h1>{{ headerMessage }}</h1>
      <div>
        <p>You've got {{ subscription.remaining_alerts }} alerts left in your {{ subscription.plan_info.name }} plan.</p> 
        <p v-if="errorMsg" class="error">{{errorMsg}}</p>
      </div>
      <button :disabled=loadingCheckoutAlert class="big-button" @click="existingSubscriptionCheckout">Start watching <u>{{ buttonLabel }}</u></button>
    </div>
    <div class="title" v-else-if="subscription && upgradeInfo == false">
      <h1>You've maxed out!</h1>
      <div>
        <p>You've got {{ subscription.remaining_alerts }} alerts left in our biggest plan, but {{ cart.length }} items in your cart.</p><p>Remove some to continue, or email andrew@bottlebeagle.com – and we can set you up with a custom plan.</p> 
        <p v-if="errorMsg" class="error">{{errorMsg}}</p>
      </div>
    </div>
    <div id="plans" v-else>
      <div class="title">
        <h1>Select a plan</h1>
        <p>Easily change or cancel from your dashboard. Questions or requests? I'm here to help: <a href="mailto:andrew@bottlebeagle.com">andrew@bottlebeagle.com</a></p>
        <div class="coupon code-entry">
          <!-- <a @click="toggleCouponField">Enter a coupon</a> -->
          <form v-if="showCouponField" class="coupon" @submit.prevent="couponCheckoutButtonPressed" >
            <div class="phonebox">
              <input v-model="couponString" placeholder="Enter coupon code" data-hj-allow>
              <button class="big-button" :disabled=loadingCoupon>Apply</button>
            </div>
            <p v-if="coupon" class="coupon-success">{{couponSuccessMessage}}</p>
            <p v-if="couponErrorMsg" class="error">{{couponErrorMsg}}</p>
          </form>
        </div>
      </div>
      <div class="card-content">
        <div class="plan-types">
          <plan-item
            v-for="plan in plans"
            v-bind:key="plan.id"
            v-bind:plan="plan"
            @selectPlan="selectPlan"
            :isSelected="selectedPlan===plan">
          </plan-item>
        </div>
        <div class="pricing-tiers" v-if="selectedPlan" ref="tiers">
          <tier-item
            v-for="tier in tiersForPlan"
            v-bind:key="tier.id"
            v-bind:tier="tier"
            @selectTier="selectTier"
            :isSelected="selectedTier===tier"
            ></tier-item>
        </div>
        <p v-if="errorMsg" class="error">{{errorMsg}}</p>
        <div v-if="loadingCheckoutAlert" class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TierItem from '@/components/TierItem.vue';
import PlanItem from '@/components/PlanItem.vue';



export default {
  name: 'Checkout',
  props: ["pricingTiers", "coordinates", "radius", "upgradeInfo", "region",],
  components: {"tier-item": TierItem, "plan-item": PlanItem},
  data() {
    let plans = [
        {
          title: "Monthly",
          tagline: "For the connoisseur",
          description: "Faster scans, more alerts, and access to historical Stock Reports",
          id: "monthly"
        },
        {
          title: "Yearly",
          tagline: "For the savvy",
          description: "Everything in Monthly – plus a discount",
          id: "yearly"
        },
        // {
        //   title: "One-off",
        //   tagline: "Only once",
        //   description: "Alerts that run once. Perfect for trying out Bottlebeagle.",
        //   id: "btb"
        // }
    ];
    return {
      selectedPlan: plans[0],
      selectedTier: null,
      couponString: null,
      coupon:null,
      loadingCoupon: false,
      couponErrorMsg: null,
      showCouponField: false,
      errorMsg: null,
      loadingCheckoutAlert: false,
      plans: plans,
    };
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    selectPlan: function (plan) {
      this.selectedPlan = plan;
      this.selectedTier = null;

      this.$emit('changedPlanType', plan);
      this.$nextTick(() => {
        console.log("scrolling");
        const yOffset = -125; 
        const y = this.$refs.tiers.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      });
    },
    selectTier: function (tier) {
      console.log("Selected tier: ", tier.id);
      this.selectedTier = tier;

      if (this.selectedPlan.id === "btb") {
        // create the payment intent for a single alert
        this.loadingCheckoutAlert = true;

        axios.post('checkout_alert/', {
          id: this.product.item_id,
          region: this.region,
          coordinates: {
            latitude: this.coordinates.latitude,
            longitude: this.coordinates.longitude,
          },
          radius: this.radius,
          product: this.selectedTier.id,
          coupon: this.coupon,
        }).then((response) => {
          this.loadingCheckoutAlert = false;
          // else:
          if (response.data.paid === true) {
            console.log("paid")
            this.$emit('paid');
          } else {
            this.$emit('checkoutAlert', response.data.total, response.data.client_secret);
          }
        }).catch((error) => {
          this.loadingCheckoutAlert = false;
          if (error.response.status >= 500) {
            this.errorMsg = "Something went wrong – we're on it."
          } else if (error.response.status === 302) {
            // If it's a 302, say they've already got this alert going.
            this.errorMsg = error.response.data.error;
          } else {
            this.errorMsg = error.response.data.error;
          }
          console.log(error);
        });
      } else {
        // checking out a subscription
        // just emit the checkout event
        console.log("Okay:", this.selectedTier);
        this.$emit('checkoutSubscription', this.selectedTier);
      }
    },
    existingSubscriptionCheckout: function() {
      // maybe let's just use the checkout_alert/ endpoint for this
      this.loadingCheckoutAlert = true;

      axios.post('checkout_alert/', {
        cart: this.cart,
        coordinates: {
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
        },
        radius: this.radius,
        product: "existing_subscription",
      }).then((response) => {
        this.loadingCheckoutAlert = false;
        // else:
        if (response.data.paid === true) {
          console.log("paid")
          this.$emit('paid');
        } else {
          this.$emit('checkoutAlert', response.data.total, response.data.client_secret);
        }
      }).catch((error) => {
        this.loadingCheckoutAlert = false;
        this.errorMsg = error.response.data;
        if (error.response.status >= 500) {
          this.errorMsg = "Something went wrong – we're on it."
        } else if (error.response.status === 302) {
          // If it's a 302, say they've already got this alert going.
          this.errorMsg = error.response.data.error;
        } else if (error.response.status === 402) {
          // they need to upgrade their subscription
          if (error.response.data.error_code === "no-alerts-remaining") {
            this.errorMsg = error.response.data.error;
            this.nextTier = error.response.data.next_tier;
          } else if (error.response.data.error_code === "highest-tier") {
            this.errorMsg = error.response.data.error;
            this.nextTier = null;
          } else {
            this.errorMsg = error.response.data.error;
          }
        } else {
          // 400?
          if (error.response.data.missing) {
            this.errorMsg = "Whoops – we haven't got that product listed yet. Give us a couple minutes and try again.";
          } else if (error.response.data.error) {
            this.errorMsg = error.response.data.error;
          } else {
            this.errorMsg = error;
          }
        }
        console.log(error);
      });
    },
    upgradeSubscriptionCheckout: function() {
      console.log("Upgrade");
      this.$emit('checkoutSubscription', this.nextTier);
    },
    couponCheckoutButtonPressed: function() {
      // call the endpoint to check the coupon and return pricing again
      this.loadingCoupon = true;

      axios.get('checkout_subscription/', {
        params: {
          coupon: this.couponString,
          region: this.region,
        },
      }).then((response) => {
        this.loadingCoupon = false;
        this.coupon = response.data.discount;
        this.couponErrorMsg = null;
        this.couponSuccessMsg = this.couponSuccessMessage
        this.$emit('couponApplied', response.data.pricing, response.data.discount);
      }).catch((error) => {
        this.loadingCoupon = false;
        if (error.response.data.error) {
          this.couponErrorMsg = error.response.data.error;
        } else {
          this.couponErrorMsg = error;
        }
      });
    },
    toggleCouponField: function () {
      this.showCouponField = !this.showCouponField;
    },
  },
  computed: {
    shouldUpgrade () {
      if (!this.subscription) {
        return false;
      }
      if (this.subscription.remaining_alerts < this.cart.length) {
        return true;
      }
      return false;
    },
    hasActiveSubscription () {
      if (this.$store.state.stripeAccount.subscription) {
        return true;
      }
      return false;
    },
    headerMessage () {
      if (this.cart.length === 1) {
        return "Confirm your alert";
      } else {
        return "Confirm your " + this.cart.length + " alerts";
      }
    },
    couponSuccessMessage () {
      let codeUpper = this.coupon.code.toUpperCase();
      
      if (this.coupon.duration_in_months) {
        return "Successfully applied code " + codeUpper + " for " + Math.floor(this.coupon.percent_off) + "% off, for " + this.coupon.duration_in_months + " months.";
      }

      return "Successfully applied code " + codeUpper + " for " + Math.floor(this.coupon.percent_off) + "% off.";
    },
    tiersForPlan () {
      return this.pricingTiers[this.selectedPlan.id];
    },
    subscription () {
      return this.$store.state.stripeAccount.subscription;
    },
    prorationPrice () {
      let priceInDollars = this.upgradeInfo.total / 100;
      return "$"+ priceInDollars.toFixed(2);
    },
    nextTierPrice () {
      let priceInDollars = this.upgradeInfo.next_tier.total / 100;
      return "$"+ priceInDollars.toFixed(2);
    },
    cart() {
      return this.$store.getters['cart/cartItems'];
    },
    buttonLabel() {
            if (this.cart.length === 1) {
                return 'one bottle'
            } else {
                return `${this.cart.length} bottles`
            }
        
        }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    p {
      max-width: 500px;
      a {
        color: black;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
      }
    }

  .big-button {
    u {
      font-weight: 600;
      text-decoration-color: black;
    }
  }
}

#plans {
  .title {

    h1 {
      margin: 0;
    }
    p{
      margin: 0;
    }


    .coupon {
      a {
        color: black;
        text-decoration: underline;
        cursor: pointer;
      }
      form {
        margin-top: 10px;
        .phonebox {
          input {
            &::placeholder {
            }
          }
        }
        p {
          // for the coupon messages
          margin-top: 12px;
        }
      }
      .big-button {

      }
    }
  }
  .card-content {
    background-color: $card-header-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .plan-types {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content:  center;
      flex-wrap: wrap;
      gap: 9px 20px;

      margin-bottom: 20px;
      max-width: 750px;
    }
    .pricing-tiers {
      width: 70%;
      max-width: 420px;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content:  space-between;
      gap: 20px;
    }
  }
}


@media only screen and (max-width: 1220px) {
  #plans {
    .plan-types {
      flex-direction: column;
      .plan {
        // width: 80%;
        margin: 0 auto;
        padding: 0.5em;

        hr {
          display: none;
        }

        h1 {
          font-size: 22px;
          margin: 0.2em;
        }

        h3 {
          font-size: 14px;
          font-style: italic;
          margin: 0;
        }

        p {

        }

        .discount {
          h1 {
            margin-bottom: 0;
            margin-top: 0;
          }
        }

      }
    }
  }
}


@media only screen and (max-width: 900px) {
  #checkout {
    #plans {

    .coupon {
      a {
        color: black;
        text-decoration: underline;
      }
      form {
        margin-top: 10px;
        .phonebox {
          input {
            &::placeholder {
            }
          }
        }
      }
      .big-button {
        font-size: 12px;
      }
    }
    }
    .pricing-tiers {
      margin: 0.5em !important;
      min-width: 200px;
      .tier {
        display: inline-block;
        width: 90%;
        // padding: 1.5em 0;

        .left {
          text-align: left;
          h1 {
            font-size: 18px;
            margin-top: 0;
          }
          h3 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
          }
        }

        .right {
          ul {
            // list-style: none;
            li {
              margin: 6px 0;
              line-height: 1.3em;
              font-size: 14px;
            }
          }

        }
      }
    }
  }
}

</style>
