<template>
  <div class="modal-container">
    <div class="login-modal modal">
      <h1>Login to Bottlebeagle</h1>
      <p>Welcome back!</p>
      <div v-if="showSpinner" class="loader"></div>
      <PhoneAuth @authenticated="wasAuthenticated"/>
    </div>
    <div class="modal-backdrop" @click="hideModal"></div>
  </div>
</template>

<script>
const PhoneAuth = () => import('./phone-auth.vue');

export default {
  components: { PhoneAuth },
  name: 'LoginModal',
  data: function () {
    return {
      showSpinner: false,
    }
  },
  methods: {
    hideModal () {
      this.$emit('dismiss');      
    },
    wasAuthenticated(newUser) {
      this.$emit('didAuthenticate', newUser);
    },
  },
}
</script>

<style lang="scss" scoped>

.modal-backdrop {
  //
}


.login-modal {
  // background: yellow;
}

</style>
