<template>
    <div v-if="isSelected" class="collection" v-bind:class="{'selected': isSelected}">
        <div class="collection-bottles">
            <product-item
                v-for="product in collection.products"
                v-bind:key="uniqueIdForCartProduct(product)"
                v-bind:product="product">
            </product-item>            
        </div>
        <button class="small-button emphasis" @click="addAll">Add All</button>
        <div class="collection-description">
            <p>{{ collection.description }}</p>
        </div>
        <div class="collection-label">
            <div></div>
            <h3>{{ collection.title }}</h3>
            <div>
              <button v-if="isSelected" @click="toggleSelected">x</button>
            </div>
        </div>
    </div>
    <div v-else class="collection" @click="toggleSelected">
      <div class="collection-bottles">
        <div class="collection-bottle" v-for="product in collection.products.slice(0,6)" v-bind:key="product.bbid">
          <transition name="fade">
            <img class="icon" v-bind:src="mediaUrlForUrl(product.image_url)" v-on:load="onLoaded(product.bbid)" v-show="loaded.includes(product.bbid)"/>
          </transition>
        </div>
      </div>
    </div>
</template>

<script>
import helperMixin from '@/mixins/helperMixin';
import ProductItem from '@/components/product-item.vue';
export default {
    'name': 'CollectionItem',
    'mixins': [helperMixin],
    'props': ['collection'],
    'components': {
        ProductItem,
    },
    'data': function() {
        return {
            isSelected: false,
            loaded: [],
        };
    },
    methods: {
        toggleSelected: function() {
            this.isSelected = !this.isSelected;
        },
        mediaUrlForUrl: function(url) {
            if (url) {
                let imageUrl = url;
                if (imageUrl.startsWith("http")) {
                    return imageUrl;
                }
                return process.env.VUE_APP_MEDIA_BASE_URL + url;
            } else {
                return process.env.VUE_APP_MEDIA_BASE_URL + "products/thumb.png";
            }
        },
        addAll: function() {
          // add all  to the cart
          for (let product of this.collection.products) {
            this.$store.dispatch('cart/addToCart', product);
          }
        },
        addProduct: function(product) {
          this.$store.dispatch('cart/addToCart', product);
        },
        onLoaded: function(bbid) {
          if (this.loaded.includes(bbid)) {
            return;
          }
          this.loaded.push(bbid);
        },
    },

}
</script>

<style scope lang="scss">

.collection {
    background: white;
    border-radius: 20px;
    border: 3px solid black;
    display: flex;
    flex-flow: row;
    flex: 1 0 auto;
    justify-content: space-evenly;
    align-items: center;
    // max-width: 300px;
    height: 100px;
    cursor: pointer;

    .fade-enter-active {
          transition: opacity 3s ease-in-out;
        }

        .fade-enter-to {
          opacity: 1;
        }

        .fade-enter {
          opacity: 0;
        }

    &.selected {
        border: 3px solid $emphasis;
        flex-flow: column-reverse;
        height: 100%;
        flex: 1 1 auto;

        cursor: default;

        .collection-bottles {
            flex-direction: column;
            gap: 5px;

            .collection-bottle {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
        }
    }

    &:hover {
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        border: 3px solid $emphasis;
    }
    .collection-bottles {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0px;

      .collection-bottle {

        img {
          height: 74px;
          width: 40px;
          object-fit: cover;
          object-position: 50% 80%;
          border-radius: 12px;
        }
      }
    }
    .collection-label {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0;
      margin: 1em 1em 0 1em;
      min-width: 100px;
      height:100%;
      width: 100%;
      flex: 1 1 auto;
      div {
        flex: 1;
        margin: 0;

        display:flex;
        justify-content: flex-end;
      }
      h3 {
        flex: 2;
        font-family: $header-font;
        text-align: center;
        padding: 0;
        margin: 0;
        align-content: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 125% */
        color: #401D18;
      }

      button {
        background: none;
        font-size: 32px;
        color: #401D18;
        cursor: pointer;
        border: 1px solid black;
        border-radius: 20px;
        padding: 0;
        width: 40px;
        height: 40px;
        margin-right: 20px;

        &:hover {
          background: $emphasis;
          color: white;
        }
      }
    }
}

</style>