<template>
  <div id="home" :style="{ backgroundColor: containerBgColor }">
    <FindProduct v-if="directlyLinkedToProduct && cart.length > 0"  v-bind:regionInfo='regionInfo' @didSelectRegion='didSelectRegion' @resetCheckout="resetCheckout" ref="findproduct"/>
    <ProductSearch v-bind:regionInfo='regionInfo' @didSelectRegion='didSelectRegion' @resetCheckout="resetCheckout" ref="searchbox" v-else/>
    <PopularProducts v-if="selectedRegion && showPopularProducts && !directlyLinkedToProduct" v-bind:regionInfo='selectedRegion' @didSelectRegion='didSelectRegion' @resetCheckout="resetCheckout"></PopularProducts>
    <Location v-if="cart.length > 0 && step.stepNumber >= 1" v-bind:region="selectedRegion" @located="locationSet" @resetCheckoutAndRegion="resetCheckoutAndRegion"/>
    <CartConfirmation v-if="cart.length > 0 && coordinates !== null && radius !== null && step.stepNumber >= 2" @confirmCart="confirmCart" v-bind:coordinates="coordinates" v-bind:radius="radius" v-bind:region="selectedRegion" v-bind:regionsInRadius="regionsInRadius" v-bind:retailers="retailers" ref="cartConfirmer"/>
    <PhoneLogin v-if="cartConfirmed && step.stepNumber == 3" @authenticated="authenticated"/>
    <Checkout v-if="token && phoneNumber && (step.stepNumber >= 4) && !successfulPayment" v-bind:upgradeInfo="upgradeInfo" @checkoutAlert="checkoutAlert" @changedPlanType='changedPlanType' @checkoutSubscription="checkoutSubscription" @couponApplied="couponApplied" v-bind:pricingTiers="pricing" v-bind:coordinates="coordinates" v-bind:radius="radius" @paid="successfullyPaid" v-bind:region="selectedRegion"/>
    <StripePayment v-if="step.stepNumber === 5 && !successfulPayment && subscriptionTier" v-bind:subscriptionTier="subscriptionTier" v-bind:pricingRegion="pricingRegion" v-bind:coordinates="coordinates" v-bind:radius="radius" v-bind:subscriptionCoupon='subscriptionCoupon' v-bind:clientSecret="stripeClientSecret" v-bind:amount="amount" v-bind:customerId="customerId" v-bind:upgradeInfo="upgradeInfo" @paid="successfullyPaid"/>
    <ConfirmedPayment v-if="successfulPayment"  @resetCheckout="resetCheckout"/>
    <AgeModal v-if="showAgeModal" @ageSet="ageSet" @noAge="ageCancelled"/>
    <div class="marketing" ref="marketing" v-if="showMarketing">
      <div class="marketing-card" id="insiders" ref="insiders">
        <div class="marketing-content">
          <div>
            <h2>Get ahead of insiders and speculators.</h2>
            <p>With Bottlebeagle, you get a fair shot at scoring the bottle you want – in store and online.</p>
          </div>
          <div id="recent-finds">
            <transition name="slide-fade" mode="out-in">
              <p :key="recentFindIndex">Found {{recentFind.count}} bottles of</p>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <h3 :key="recentFindIndex">{{recentFind.name}}</h3>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <p :key="recentFindIndex">{{recentFind.mode}} in {{recentFind.location}}</p>
            </transition>
          </div>
        </div>
        <div class="marketing-image">
          <img src="../assets/bros.webp"/>
        </div>
      </div>
      <div class="marketing-card" id="pager" ref="pager">
        <div class="marketing-image">
          <img src="../assets/pager.webp"/>
        </div>
        <div class="marketing-content">
          <h2>Instant alerts, right to your phone. Or pager.</h2>
          <p>Want an extra edge? We can check for new stock as frequently as every few minutes.</p>
          <button class="big-button" @click="startFirstCTA">Start your first alert</button>          
        </div>
      </div>
      <div class="marketing-card" id="care" ref="care">
        <div class="marketing-content">
          <h2>Relax. We've got our eyes peeled.</h2>
          <p>Even if a rare bottle has never been in stock near you, we automatically start watching it when it becomes available.</p>
          <button class="big-button" @click="pricingCTA">See pricing</button>
        </div>
      </div>
      <div class="marketing-card" id="global" ref="global">
        <div class="marketing-content">
          <h2>Get a global view of the latest stock.</h2>
          <p>With our Stock Reports, you get a historical view of all the popular bottles, in all available retailers and regions.</p>
        </div>
        <div>
          <button class="big-button" onclick="location.href='/stock-reports/'" type="button">See stock history</button>
        </div>
      </div>
      <div class="marketing-card" id="news-cta" ref="news-cta">
        <div class="marketing-content">
          <h2>“I find out about new bottles from the Bottlebeagle Beat.”</h2>
          <p>Our region-based newsletter tells you about all the newest, hottest, rarest bottles available near you.</p>
        </div>
        <div class="newsletter">
          <h2 v-if="selectedRegion">Get the weekly {{ selectedRegion.name }} newsletter:</h2>
          <h2 v-else>Get the weekly newsletter:</h2>
          <form @submit.prevent="newsletterSubmitButtonPressed" >
            <div class="phonebox">
              <input v-model="newsletterEmail" placeholder="Enter your email" data-hj-allow>
              <button class="big-button" :disabled=loadingNewsletter>Subscribe</button>
            </div>
            <p v-if="newsletterSuccess" class="success">{{newsletterSuccess}}</p>
            <p v-if="newsletterError" class="error">{{newsletterError}}</p>
          </form>
        </div>
      </div>
    </div>
    <div id="faq" ref="faq" v-if="showFaq">
      <h1>FAQs</h1>
      <div id="faq-items">
        <faq-item v-for="faq in faqItems" v-bind:title="faq.title" v-bind:content="faq.content" v-bind:key="faq.title" :ref="faq.ref"></faq-item>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSearch from '@/components/ProductSearch.vue';
import FindProduct from '@/components/find-product.vue';
const Location = () => import('@/components/Location.vue');
import CartConfirmation from '@/components/CartConfirmation.vue';
import PhoneLogin from '@/components/PhoneLogin.vue';
import Checkout from '@/components/Checkout.vue';
import StripePayment from '@/components/StripePayment.vue';
import ConfirmedPayment from '@/components/ConfirmedPayment.vue';
import FaqItem from '@/components/faq-item.vue';
import AgeModal from '@/components/AgeModal.vue';

import axios from 'axios';
import PopularProducts from '@/components/popular-products.vue';

function initialData () {
  return {
    coordinates: null,
    radius: null,
    pricing: null,
    upgradeInfo: null,
    amount: null,
    subscriptionTier: null,
    alert: null,
    subscriptionCoupon: null,
    cartConfirmed: false,
    locationConfirmed: false,
    retailers: null,
    pricingRegion: null,
    regionsInRadius: null,
    stripeClientSecret: null,
    successfulPayment: null,
    regionInfo: null, // the Geonames API-returned region info
    selectedRegion: null, // an object from country-region-data, which we canonically use as the selected region to affect searches, popular products, etc.
    directlyLinkedToProduct: false,
    clickedVid: false,
    containerBgColor: "#401D18",
    subDivs: [
      {id: "insiders", bgColor: "#193620", ref: "insiders", color: "#210F1B"},
      {id: "pager", bgColor: "#5469d4", ref: "pager", color: "#401D18"},
      {id: "care", bgColor: "#19245F", ref: "care", color: "#210F1B"},
      {id: "global", bgColor: "#090019", ref: "global", color: "#EDD0C5"},
      {id: "news-cta", bgColor: "#401D18", ref: "news-cta", color: "#EDD0C5"},
      {id: "faq", bgColor: "#401D18", ref: "faq", color:"white"}
    ],
    recentFinds: [
      {count: 537, name: "Blanton's Original", location: "Ontario", mode: "online"},
      {count: 12, name: "Eagle Rare", location: "Florida", mode: "in-store"},
      {count: 56, name: "E.H. Taylor", location: "British Columbia", mode: "allocated"},
    ],
    faqItems: [
      {title: "How much does Bottlebeagle cost?", content: "<p>Bottlebeagle starts at $4.99 a month, or $41.99 a year. If you want more alerts, more frequent checking, and other perks, we've got higher tiers, too.</p>", ref: "pricingfaq"},
      {title: "How do you know what's in stock?", content: "<p>After a number of years, we've gotten good at reliably and respectfully checking retailers for their stock.</p>", ref:'howdoesitwork'},
      {title: "Can Bottlebeagle buy a bottle for me?", content: "<p>No, we can't buy bottles for you. We're just here to help you find them. After all, we wouldn't want to deprive you of the thrill of the chase.</p>", ref: "buybottle"},
      {title: "How do I get alerts?", content: "<p>After you've selected a product in a specific region, and a location for us to check, we'll send you an SMS to confirm we're looking for it. The next time you'll hear from us is when it's back in stock.</p>", ref: "getalerts"},
      {title: "What if I have issues, or don't like the service?", content: "<p>We're here to help. Just send us an email at <a href='mailto:andrew@bottlebeagle.com'>andrew@bottlebeagle.com</a> and we'll do our best to make it right.</p>", ref: "issues"},
    ],
    recentFindInterval: '',
    recentFindIndex: 0,
    newsletterError: null,
    newsletterSuccess: null,
    newsletterEmail: '',
    loadingNewsletter: false,
    showPopularProducts: true,
    showAgeModal: false,
  }
}

export default {
  name: 'Home',
  components: {
    ProductSearch,
    FindProduct,
    AgeModal,
    Location,
    CartConfirmation,
    PhoneLogin,
    Checkout,
    StripePayment,
    ConfirmedPayment,
    FaqItem,
    PopularProducts,
},
  data() {
    return initialData();
  },
  async created() {
    if (this.directlyLinkedToProduct == true) {
      // they may have reset the search after coming from the product stock page
      // if there's no URL params for an item and retailer, then let's reset
      if (!this.$route.query.itemId && !this.$route.query.retailer) {
        this.directlyLinkedToProduct = false;
        this.coordinates = null;
        this.radius = null;
        this.pricing = null;
        this.selectedTier = null;
        this.stripeClientSecret = null;
        this.pricingRegion = null;
        this.regionsInRadius = null;
        this.successfulPayment = null;
        this.subscriptionTier = null;
        this.alert = null;
        this.discountInfo = null;
        this.originalBgPosition = null;
      }
    } else {
      // let's check for url params
      if (this.$route.query.itemId && this.$route.query.retailer) {
        // they came in with a retailer and item ID, get the product info
        this.directlyLinkedToProduct = true;
        // call FindProductView API for product info
        this.getRegionByIp().then((fullRegionInfo) => {
          // try to get the region object
          axios.post('search/product/', {
            item_id: this.$route.query.itemId, // from the url query param
            retailer: this.$route.query.retailer, // from the url query param
            region: fullRegionInfo,
          }).then((response) => {
            this.pricing = response.data.pricing;
            this.$store.dispatch('cart/addToCart', response.data.product);
            this.product
          }).catch((error) => {
            // in case our API returns something silly
            console.log("Error:", error)
            this.directlyLinkedToProduct = false;
          });

        });
      } else {
        // it's just a normal homepage hit, get the region
        this.getRegionByIp()
          .catch(
            // uhhhhh
          );
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.recentFindInterval = setInterval(() => {
      this.recentFindIndex++;
    }, 3500);

    // check if there's a specific product, and hide hero if so
    if (this.$route.query.itemId && this.$route.query.retailer && this.directlyLinkedToProduct) {
      const heroElement = document.getElementById('hero');
      if (heroElement) {
        heroElement.classList.add('hide-hero');
      }
    } else {
      const heroElement = document.getElementById('hero');
      if (heroElement) {
        heroElement.classList.remove('hide-hero');
      }
    }
    // if there's no stored token, show the age modal
    if (!this.token) {
      this.showAgeModal = true;
      this.$store.dispatch('showedAgeModal');
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  // now watch if selectedItem changes
  watch: {
    cart: {
      immediate: true,
      handler (newVal, oldVal) { // watch it
        console.log("cart changed");
        if (newVal.length > 0) {
          if (this.$store.state.showedAgeModal === false && !this.token) {
            this.showAgeModal = true;
            this.$store.dispatch('showedAgeModal');
          }
        }
      }
    },
    directlyLinkedToProduct: function(newVal, oldVal) {
      const heroElement = document.getElementById('hero');
      if (!heroElement) {
        return;
      }
      if (newVal == false) {
        if (heroElement) {
          heroElement.classList.remove('hide-hero');
        }
      } else if (newVal == true) {

        if (heroElement) {
          heroElement.classList.add('hide-hero');
        }
      }
    },
    step: function(newVal, oldVal) {
      if (newVal.stepNumber > 0) {
        this.containerBgColor = "#401D18";
      }
    },
  },
  methods: {
    newsletterSubmitButtonPressed: function() {
      this.loadingNewsletter = true;
      this.newsletterSuccess = null;
      this.newsletterError = null;
      axios.post('subscribe/', {
        email: this.newsletterEmail,
        region: this.selectedRegion,
      }).then((response) => {
        this.newsletterSuccess = response.data;
        this.loadingNewsletter = false;
      }).catch((error) => {
        this.newsletterError = error.response.data;
        this.loadingNewsletter = false;
      });
    },
    handleScroll() {
      this.subDivs.forEach((div, index) => {
        let element = this.$refs[div.ref]; // Accessing the DOM element
        if (!element) {
          return;
        }

        // if showMarketing is false, reset the color and return
        if (!this.showMarketing) {
          this.containerBgColor = "#401D18";
          return;
        }

        if (this.originalBgPosition != null && window.scrollY < this.originalBgPosition) {
          // if we've scrolled back up, reset the bg color
          this.containerBgColor = "#401D18";
          this.originalBgPosition = null;
        }
        else if (element.getBoundingClientRect().top <= 0 && element.getBoundingClientRect().bottom >= 0) {
          // if the top of the element is at the top of the screen
          this.containerBgColor = div.bgColor; // Change the container's background color
        }
        else if (element.getBoundingClientRect().top <= (window.innerHeight / 3) && element.getBoundingClientRect().bottom >= (window.innerHeight / 3)){
          // if the middle of the element is at the middle of the screen
          if (div.ref == "insiders" && this.originalBgPosition == null) {
            // if we're at the top of the insiders section, save the scroll position
            this.originalBgPosition = window.scrollY;
          }

          this.containerBgColor = div.bgColor; // Change the container's background color
        }
      });
    },
    pressedVid() {
      this.clickedVid = true;
    },
    findRegionForRegionName(regionName) {
      if (!regions) {
        return;
      }

      var results = regions.filter(function (entry) { return entry.name === regionName; });
      if (results.length > 0) {
        return results[0];
      } else {
        console.log("Couldn't find " + regionName + " in regions")
        return null;
      }
    },
    didSelectRegion: function(regionObject) {
      if (this.selectedRegion !== regionObject && !(this.$route.query.itemId && this.$route.query.retailer && this.directlyLinkedToProduct)) {
        this.resetCheckout();
      }
      this.selectedRegion = regionObject;
    },
    getRegionByIp: function() {
      return new Promise((resolve, reject) => {
        axios.get('https://ipinfo.io/json?token=f78e3d15d3f68a',  {withCredentials: false, transformRequest: [(data, headers) => {
            if (headers.Authorization) {
              delete headers.Authorization;
            }
            return data;
        },...axios.defaults.transformRequest]},)
        .then((response) =>{
          this.ipRegionInfo = response.data;
          var [lat, lon] = response.data.loc.split(',');

          axios.get(`https://secure.geonames.net/countrySubdivisionJSON?lat=${lat}&lng=${lon}&username=bbuser&level=3`,  {withCredentials: false, transformRequest: [(data, headers) => {
            if (headers.Authorization) {
              delete headers.Authorization;
            }
            return data;
        },...axios.defaults.transformRequest]},)
            .then((response_2) => {
              this.regionInfo = response_2.data;
              resolve(response_2.data);
            }).catch((error) => {
              this.regionInfo = response.data;
              resolve(response.data);
            });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    locationSet: function (coords, radius, pricing, regions, pricingRegion, upgradeInfo, retailers) {
      this.coordinates = coords;
      this.radius = radius;
      this.pricing = pricing;
      this.regionsInRadius = regions;
      this.pricingRegion = pricingRegion;
      this.upgradeInfo = upgradeInfo;
      this.retailers = retailers;
      console.log("Got location and radius:", this.coordinates, "-", this.radius);
      this.$posthog.capture('location_chosen', { 'coordinates': coords, 'radius': radius, 'retailers_count': retailers.length });

      this.$nextTick(() => {
        this.$refs.cartConfirmer.$el.scrollIntoView({ block: 'center', scrollBehavior: 'smooth' });
      });

      this.step = 2;
    },
    confirmCart: function () {
      this.cartConfirmed = true;
      this.$posthog.capture('cart_confirmed', { 'cart_size': this.cart.length });
      // does the user have a token?
      if (this.token) {
        // does the user have enough space in their plan?
        // first check if they have a subscription
        let sub = this.$store.state.stripeAccount.subscription;
        // now check if they have enough space
        if (this.upgradeInfo != false) {
          // there is an available upgrade, so check if we should show it
          if (sub && sub.remaining_alerts >= this.cart.length) {
            // they have enough space, let's go right to confirm
            this.step = 5;
          } else {
            // they need to upgrade
            this.step = 4;
          }
        } else { // there's no further upgrade available
          this.step = 4;
        }
      } else {
        // they need a token, and therefore must login or signup
        this.step = 3;
      }
    },
    authenticated: function (newUser) {
      console.log("Authenticated successfully");
      this.newUser = newUser;
      this.step = 4;
    },
    changedPlanType: function (plan) {
      this.amount = null;
      this.stripeClientSecret = null;
      this.subscriptionTier = null;
    },
    checkoutAlert: function (amount, stripeClientSecret) {
      this.amount = amount;
      this.stripeClientSecret = stripeClientSecret;
      this.step = 6;
    },
    couponApplied: function(newPricing, subscriptionCoupon) {
      console.log("Heard coupon applied in Home");
      this.subscriptionCoupon = subscriptionCoupon;
      this.pricing = newPricing;
      this.$posthog.capture('coupon_applied', { 'coupon': subscriptionCoupon });
    },
    checkoutSubscription: function (subscriptionTier, coupon) {
      this.step = 5;
      this.subscriptionTier = subscriptionTier;
      console.log("Selected this tier: ", this.subscriptionTier);
      this.$posthog.capture('checkout_subscription', { 'tier': subscriptionTier, 'coupon': coupon });
      this.alert = {
        id: this.cart,
        region: this.selectedRegion,
        coordinates: {
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
        },
        radius: this.radius,
      };
      this.discountInfo = coupon;

      this.step = 5;
    },
    successfullyPaid: function () {
      console.log("Successfully started alerts");
      this.$posthog.capture('alerts_created', { 'cart_size': this.cart.length });
      this.successfulPayment = true;
      // now empty the cart
      this.$store.dispatch('cart/clearCart');
    },
    resetCheckout: function () {
      console.log("Resetting");
      this.coordinates = null;
      this.directlyLinkedToProduct = false;
      this.radius = null;
      this.$store.dispatch('resetStep');
      this.pricing = null;
      this.selectedTier = null;
      this.stripeClientSecret = null;
      this.pricingRegion = null;
      this.retailers = null;
      this.regionsInRadius = null;
      this.successfulPayment = null;
      this.subscriptionTier = null;
      this.alert = null;
      this.discountInfo = null;
      this.directlyLinkedToProduct = false;
      window.scrollTo({top: 0});

      if (this.$refs.searchbox) {
        this.$refs.searchbox.resetSearch();
      }
    },
    resetCheckoutAndRegion: function() {
      this.resetCheckout();
      this.regionInfo = null;
      this.selectedRegion = null;
      this.didSelectRegion(null);
    },
    startFirstCTA: function() {
      // scroll to searchbox
      if (this.$refs.findproduct) {
        this.$refs.findproduct.$el.scrollIntoView({ behavior: 'smooth', block: "center"});
      } else if (this.$refs.searchbox) {
        this.$refs.searchbox.$el.scrollIntoView({ behavior: 'smooth', block: "center" });
      } else {
        window.scrollTo({top: 0});
      }
    },
    pricingCTA: function() {
      if (this.$refs.pricingfaq) {
        this.$refs.pricingfaq[0].$el.scrollIntoView({ behavior: 'smooth', block: "center"});
        this.$refs.pricingfaq[0].toggleHighlight();
      } else {
        window.scrollTo({top: 0});
      }
    },
    ageSet(date) {
      console.log("setAge:", date);
      this.showAgeModal = false
    },
    ageCancelled() {
      console.log("cancelled");
      this.showAgeModal = false
      this.$emit('resetCheckout');
    },
    trackRedditEvent(eventName, customData = {}) {
      if (window.rdt) {
        if (Object.keys(customData).length === 0) {
          window.rdt('track', eventName);
        } else {
          window.rdt('track', eventName, customData);
        }
      }
    },
    toggleFaq() {
      this.showFaq = !this.showFaq;
    }
  },
  computed: {
    step: {
      get() {
        return this.$store.getters['step'];
      },
      set(value) {
        this.$store.dispatch('setStep', value);
      }
    },
    showFaq: function() {
      return this.step.stepNumber < 1;
    },
    cart: function() {
      return this.$store.getters['cart/cartItems'];
    },
    recentFind: function() {
      return this.recentFinds[this.recentFindIndex % this.recentFinds.length];
    },
    phoneNumber: function() {
      return this.$store.state.phoneNumber;
    },
    customerId: function() {
      return this.$store.state.stripeAccount.id;
    },
    token: function() {
      return this.$store.state.token;
    },
    showMarketing: function() {
      return this.step.stepNumber < 1;
    },
  }
};


</script>

<style scoped lang="scss">
#home {
  transition: background-color 0.3s ease;
  margin-bottom: 4em;
}
.faq-displayer {
  margin: 200px auto 150px auto;
}

.marketing {
  max-width: 1328px;
  width: 80%;
  margin: 150px auto 50px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 100px;
  .marketing-card {
    height: 535px;
    width: 100%;

    background: transparent;
    border: 1px solid $emphasis;
    border-radius: 20px;
    padding: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    .big-button {
      font-weight: 800;
    }

    .marketing-content {
      max-width: 720px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      z-index: 10;

      h2 {
        font-family: $header-font;
        font-size: 36px;
        margin-bottom: 20px;
        margin: 0 0 10px 0;
        line-height: 34px;
      }
      p {
        font-size: 20px;
        line-height: 1.5em;
        margin: 0;
      }
      .big-button {
        margin: 20px 0;
      }
    }
    .marketing-image {
      z-index: 0;
      max-width: 50%;
      img {
        width: 100%;
      }
    }

    &#pager {
      background: #ffaf00;
      .marketing-image {
        height: 100%;
        width: 100%;
        img {
          position: relative;
          z-index: -1;
          top: 0px;
          left: -50px;
          width: 608px;
          height: 566px;
          object-fit: contain;
        }
      }
      .marketing-content {
        max-width: 50%;
        h2 {
          font-family: $header-font;
          font-size: 36px;
          margin-bottom: 20px;
          margin: 0 0 10px 0;
        }
        p {
          font-size: 20px;
          margin: 0;
        }
        .big-button {
          background: $payment-button;
        }
      }
    }

    &#insiders {
      color: #210F1B;
      background: #EBE6DD;

      .slide-fade-enter-active {
        transition: all .3s ease;
      }
      .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
      }
      .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active for <2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
      }

      #recent-finds {
        background: white;
        width: 60%;
        min-width: 350px;
        border-radius: 8px;
        padding: 1em;
        h3 {
          margin: 0;
          font-size: 30px;
          font-family: $header-font;
          text-decoration-color: $emphasis;
          text-decoration-line: underline;
        }
        p {
          margin: 0;
          font-style: italic;
        }
      }
      .marketing-content {
        height: 100%;
        justify-content: center;
        gap: 40px;

        div {
          max-width: 500px;
          h2 {

          }
          p {

          }
        }
      }
      .marketing-image {
        img {
          position: relative;
          left: 100px;

          height: 700px;
          width: 700px;

          object-fit: cover;
        }
      }
    }

    &#care {
      flex-direction: column;
      justify-content: flex-start;
      color: #EDD0C5;
      gap: 0px;

      background-image: url(../assets/sleepyeepy.webp);
      background-size: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;
      .marketing-content {
        justify-content: center;
        width: 100%;
        .big-button {
          background: #893225;
        }
      }
    }

    &#global {
      color: #f0eff5;
      background: #090019;
      justify-content: space-between;
      flex-direction: column;
      background-image: url(../assets/earth.webp);
      background-size: 800px;
      background-position-x: center;
      background-position-y: 80%;
      background-repeat: no-repeat;
      .marketing-content {
        justify-content: center;
        width: 100%;
      }
      div {
        .big-button {
          background: #ff9d00;
          color: #6140a9;
        }

      }
    }

    &#news-cta {
      color: #EDD0C5;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.2)), url(../assets/guys.webp);
      background-size: cover;

      flex-direction: column;
      justify-content: space-around;

      .marketing-content {
        h2 {
          font-family: $header-font;
          font-size: 36px;
          margin-bottom: 20px;
          margin: 0;
        }
        p {
          font-size: 20px;
          margin: 0;
        }
      }
      .newsletter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        background: white;
        border-radius: 8px;
        padding: 1em;
        h2 {
          margin: 0;
          font-family: $header-font;
          color: black;
        }
        p {
          font-size: 18px;
          &.success {
            color: green;
          }
          &.error {
            color: red;
          }

        }
      }

    }

  }
}

#faq {
  color: #EDD0C5;
  max-width: 800px;
  width: 80%;
  margin: 175px auto;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 100px;

  h1 {
    font-family: $header-font;
    font-size: 36px;
    margin: 0;
  }

  #faq-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

  }
}

@media screen and (max-width: 1500px) {
  .marketing {
    .marketing-card{
      &#pager {
        flex-direction: column;
        .marketing-image {
          width: 100%;
          min-width: 100%;
          img {
            -webkit-filter:contrast(1.5);
            height: 100%;
            max-height: 318px;
            width: 100%;
            max-width: 407px;
            object-fit: cover;
            // position:absolute;
            position: relative;
            left: 0px;
            z-index: -1;
          }
        }
        .marketing-content {
          max-width: 750px;
          width: 100%;
          max-height: 50%;
        }
      }
    } 
  }

}

@media screen and (max-width: 800px) {
  .marketing {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 100px;

    width: 100%;
    .marketing-card {
      background: transparent;
      border: 1px solid $emphasis;
      border-radius: 20px;
      
      width: calc(100% - 42px);
      height: 100%;
      padding: 0 20px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 0px;

      &#insiders {
      color: #EBE6DD;
      background: #EBE6DD;
      display: block;
      position: relative;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.385), rgba(0, 0, 0, 0.385)), url('../assets/bros.webp');
      background-size: 1000px;
      background-position: center;

      min-height: 500px;
      height: 500px;

      // width: 100%; /* Ensure it takes full width of its container */
      // height: 100vh; /* Example height, adjust as needed */

      #recent-finds {
        color: black;
        max-width: 100%;
      }
      .marketing-content {
        max-width: 90%;
        margin: auto;
        h2 {
          
        }
        p {

        }
      }
      .marketing-image {
        display: none;
        width: 100%;
        height: 100%;
        img {
          position: relative;

          height: 600px;
          width: 600px;

          object-fit: cover;
        }
      }
    }

    &#global {
      height: 500px;
      padding-top: 2em;
      padding-bottom: 2em;

      .marketing-content {

        max-width: 100%;
      }
      div {

      }
    }

    &#care {
      flex-direction: column;
      justify-content: flex-start;
      color: #EDD0C5;
      gap: 0px;
      min-height: 500px;

      background-image: url(../assets/sleepyeepy.webp);
      background-size: 100%;
      background-position-y: 100%;
      background-repeat: no-repeat;
      .marketing-content {
        justify-content: center;
        width: 100%;
        max-width: 100%;
        padding: 2em;
      }
    }

    &#news-cta {
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 2em;
      padding-bottom: 2em;
      gap: 40px;
      height: 500px;
      .marketing-content{
        width: 100%;
        max-width: 100%;
      }
      .newsletter {
        h2 {
          font-size: 24px;
        }
        .phonebox{
          margin: 2px;
        }
        .big-button {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }


    .marketing-content {
      max-width: 50%;
      h2 {
        font-family: $header-font;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      p {
        font-size: 20px;
        line-height: 20px;
      }
    }
    .marketing-image {
      max-width: 50%;
      img {
        width: 100%;
      }
    }
    }
  }

  #faq {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: 80px auto;
    #faq-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
}
</style>
