<template>
  <div id="cart-confirmation" class="component card" ref="cart-confirmation">
      <div class="title">
          <h1>{{ headerMessage }}</h1>
          <p v-html="subheaderMessage"></p>
      </div>
      <div class="content">
          <div class="stock-state-container">
              <div v-for="(column, index) in visibleColumns" :key="index" :class="['stock-state', column.class]">
                <a @click.prevent.stop="toggleColumnOpen(column.class)"><h3>{{ column.message }}: {{ column.products.length }} products</h3></a>
                <ul v-if="column.class == 'available' && column.open == true" >
                  <li v-for="product in column.products" :key="product.unique_id" v-tooltip="productTooltipText(product)">
                        {{ product.name }}
                  </li>
                </ul> 
                <ul v-else-if="column.open == true">
                  <li v-for="product in column.products" :key="product.unique_id">
                        {{ product.name }}
                  </li>
                </ul>
              </div>
          </div>
      </div>
      <!-- <div class="recommendations" ref="recommendations" v-if="showRecommendations">
          <div>
              <h1>You might also be interested in...</h1>
              <div class="recommendation-gutter" ref="scrollContainer" 
                      @mouseover="stopAutoScroll"
                      @mouseout="startAutoScroll"
                      @touchstart="stopAutoScroll">
                  <product-item 
                      v-for="(product, index) in displayedRecs" 
                      :key="index"
                      :product="product">
                  </product-item>
              </div>
          </div>
      </div> -->
      <div class="card-footer" v-if="currentStep.stepNumber == 2">
          <button class="big-button" @click="confirm" v-html="confirmButtonText"></button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CartConfirmation',
  props: ['product', 'region', 'coordinates', 'radius', 'regionsInRadius', 'retailers'],
  // components: {
  //     'product-item': () => import('@/components/product-item.vue'),
  // },
  data() {
      return {
          stockStates: [
              { state: ['available', 'delisted'], message: "Previously seen", class: "available", open: false },
              { state: ['unlisted'], message: "Not yet seen", class: "delisted", open: false },
              { state: [], message: "Already watching in this area", class: "unlisted", open: false },
              // { state: [], message: "All Products", class: "everything" },
          ],
          recs: null,
          // displayedRecs: [],
          scrollInterval: null,
          scrollPosition: 0,
          speed: 2, 
          // showRecommendations: false,
      }
  },
  mounted() {
      // this.initDisplayedRecs();
      // this.startAutoScroll();
  },
  methods: {
      // initDisplayedRecs() {
      //     axios.post('recommendations/', {
      //         cart: this.cart,
      //         retailer_ids: this.retailers.map(r => r.id),
      //     }).then(response => {
      //         this.recs = response.data.popular_metaproducts;
      //         this.displayedRecs = [...this.recs, ...this.recs];
      //     }).catch(error => {
      //         console.log(error);
      //     });
      // },
      // startAutoScroll() {
      //     if (!this.recs) return;
      //     if (this.scrollInterval) return;
      //     const scrollContainer = this.$refs.scrollContainer;
      //     this.scrollInterval = setInterval(() => {
      //         this.scrollPosition += this.speed;
      //         if (this.scrollPosition >= scrollContainer.scrollWidth / 2) {
      //             this.scrollPosition = 0;
      //         }
      //         scrollContainer.scrollLeft = this.scrollPosition;
      //     }, 30);
      // },
      // stopAutoScroll() {
      //     clearInterval(this.scrollInterval);
      //     this.scrollInterval = null;
      // },
      confirm() {
          // if (this.showRecommendations) {
          //     this.$emit('confirmCart', this.displayedRecs);
          // } else {
          //     this.showRecommendations = true;
          //     this.$nextTick(() => {
          //         this.$refs.recommendations.scrollIntoView({ block: 'center', scrollBehavior: 'smooth' });
          //     });
          // }
          this.$emit('confirmCart');
      },
      productsForColumn(column) {
        const products = this.$store.getters['cart/cartItems'];
        if (column.class === 'available') {
            // Include products that are available or delisted, excluding those with existing alerts
            let res = products.filter(p => column.state.includes(p.cartStatus) && !p.existingAlert);
            return res;
        } else if (column.class === 'delisted') {
            // Include products that are unlisted, excluding those with existing alerts
            let res = products.filter(p => column.state.includes(p.cartStatus) && !p.existingAlert);
            return res;
        } else if (column.class === 'unlisted') {
            // Include only products with existing alerts
            let res = products.filter(p => p.existingAlert);
            return res;
        } else if (column.class === 'everything') {
            // Include all products
            return products;
        }
        return [];
      },
      productTooltipText(product) {
          if (product.cartStatus === 'delisted') {
              return product.lastStock === null ? 'Delisted, never seen' : `Delisted, last seen on ${product.lastStock}`;
          } else {
              return product.lastStock === null ? 'Listed but not yet seen' : `Last seen on ${product.lastStock}`;
          }
      },
      toggleColumnOpen(columnClass) {
        const column = this.stockStates.find(state => state.class === columnClass);
        column.open = !column.open;
      }
  },
  computed: {
      currentStep() {
          return this.$store.getters['step']
      },
      columns() {
          return this.stockStates.map(state => ({
              ...state,
              products: this.productsForColumn(state)
          }));
      },
      visibleColumns() {
          // Filter columns to show only those that have products
          return this.columns.filter(column => column.products.length > 0);
      },
      confirmButtonText() {
        return `<h6>Watch for ${this.bottleCountMessage}</h6><h5>online + ${ this.storeCount } stores</h5>`;
      },
      headerMessage() {
          return this.cart.length > 1 ? `Sniffing for ${this.cart.length} products` : `Sniffing out your bottle`;
      },
      bottleCountMessage() {
            return this.cart.length > 1 ? `${this.cart.length} bottles` : `one bottle`;
      },
      subheaderMessage() {
          if (this.storeCount < 1) {
              return `We'll constantly scan <em>all online stock in ${this.regionMessage}</em>.`;
          }
          return `We'll constantly <em>scan all online stock, plus ${this.storeMessage}</em> in your search radius across <em>${this.retailerMessage} in ${this.regionMessage}</em>.`;
      },
      storeCount() {
          return this.retailers.map(r => r.store_count).reduce((a, b) => a + b, 0);
      },
      storeMessage() {
          return this.storeCount > 1 ? `${this.storeCount} stores` : `one store`;
      },
      retailerMessage() {
          return this.retailers.length > 1 ? `${this.retailers.length} retailers` : `one retailer`;
      },
      regionMessage() {
          if (this.regionsInRadius.length > 2) {
              return this.regionsInRadius.map(r => r.name).slice(0, -1).join(', ') + ', and ' + this.regionsInRadius.slice(-1);
          } else if (this.regionsInRadius.length === 2) {
              return `${this.regionsInRadius[0].name} and ${this.regionsInRadius[1].name}`;
          } else if (this.regionsInRadius.length === 1) {
              return this.regionsInRadius[0].name;
          }
          return false;
      },
      cart() {
          return this.$store.getters['cart/cartItems'];
      }
  }
}
</script>

<style lang="scss">
    #cart-confirmation {
        .card-footer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            height: unset;
            min-height: 100px;
            gap: 0px;
            margin-top: 2rem;

            .big-button{
                h5 {
                    margin: 0;
                    font-size: 18px;
                }
                h6 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 400;
                }
            }

            .phonebox {
                .big-button {
                    background: #d9ff00;
                }
                width: 100%;
                input {
                    width: 100%;
                }
            }

            div {
                flex-grow: 1;
            }

        }

    }

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .stock-state-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: top;
        gap: 10px;
        width: 100%;
    }

    .stock-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;

        height: 100%;
        width: 200px;
        margin-bottom: 1rem;
        padding: 3px;
        border-radius: 5px;

        background-color: white;
        color: #333;

        font-size: 16px;
        text-align: center;

        a {
            cursor: pointer;
            color: #333;
            display: block;
            padding: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            transition: background-color 0.2s;

            &:hover {
                background-color: #f5f5f5;
            }
          h3 {
            font-weight: 600;
            font-size: 16px;
            margin: 0 0 5px 0;
          }

        }

        &.available {
            background-color: #DFF0D8;

            h3::before {
                content: '';
                display: inline-block;
                width: 12px;  /* Adjust size as needed */
                height: 12px;  /* Adjust size as needed */
                background-color: #00FF00;  /* Green color */
                border-radius: 50%;
                margin-right: 8px;  /* Adjust spacing as needed */
            }

            ul {
                li {
                    text-decoration: dotted underline;
                }
            }
        }
        
        &.delisted {
            background-color: #FFF3CD;

            h3::before {
                content: '';
                display: inline-block;
                width: 12px;  /* Adjust size as needed */
                height: 12px;  /* Adjust size as needed */
                background-color: #FFD700;  /* Red color */
                border-radius: 1px;
                margin-right: 8px;  /* Adjust spacing as needed */
            }
        }

        &.unlisted {
            background-color: #ffc49b;

            h3::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                margin-right: 12px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 12px solid $emphasis;
            }
        }
    }

    .stock-state ul {
        font-size: 14px;
        padding: 0;
        margin: 0;
    }

    .stock-state ul li {
        margin-bottom: 0.5rem;
        // remove the bullet points
        list-style-type: none;
    }

    .recommendations {
        background-color: rgb(64, 29, 24);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        overflow-x: auto;

        padding: 15px 0;

        h1 {
            color: white;
            margin-bottom: 1rem;
            font-family: $header-font;
        }

        .recommendation-gutter {
            display: flex;
            flex-flow: column wrap;
            gap: 14px;
            padding: 14px;
            max-height: 200px;

            overflow-x: auto; /* Allow manual scrolling */
            white-space: nowrap;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
        }
    }

@media only screen and (max-width: 650px) {
    #cart-confirmation {
        .card-footer {
            .big-button {
                width: 100%;
            }
        }
    }

    .stock-state-container {
        flex-direction: column;
        align-content: center;
        align-items: center;
        gap: 0px;
    }

    .stock-state {
        width: 90%;
    }
}
</style>