import Vue from 'vue';
import App from './App.vue';
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import posthogPlugin from "./plugins/posthog"; // import the plugin
import { nextTick } from 'vue';

Vue.use(posthogPlugin); // install the plugin, before new Vue()
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://11a6d4bd18c047a39a08e4ad9be6ec12@o26722.ingest.sentry.io/6113582",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "127.0.0.1", "api.bottlebeagle.com"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // logErrors: true,
  });
}


new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
Vue.mixin({
  methods: {
    scrollToElement() {
      const el = this.$el;

      if (el) {
        const yOffset = -150;
        const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({top: y, behavior: "smooth"});
      }
    },
    scrollToCenterElement() {
      const el = this.$el;

      if (el) {
        const yOffset = -300;
        const y = el.getBoundingClientRect().top + window.scrollY - el.getBoundingClientRect().height / 2;
        window.scrollTo({top: y, behavior: "smooth"});
      }
    },
    timeConverter(unixTimestamp) {
      var date = new Date(unixTimestamp * 1000);
      return date
    },
    shortDate(unixTimestamp) {
      let date = this.timeConverter(unixTimestamp);
      return date.toLocaleDateString('en-US');
    },
    dateAndTime(unixTimestamp) {
      let date = this.timeConverter(unixTimestamp);
      return date.toLocaleString('en-US');
    },
  }
})

Vue.directive('tooltip', {
  bind(el, binding) {
      let tooltipElement = document.createElement('div');
      tooltipElement.className = 'tooltip';
      tooltipElement.textContent = binding.value;
      tooltipElement.style.position = 'absolute';
      tooltipElement.style.backgroundColor = '#333';
      tooltipElement.style.color = '#fff';
      tooltipElement.style.padding = '5px 10px';
      tooltipElement.style.borderRadius = '5px';
      tooltipElement.style.whiteSpace = 'nowrap';
      tooltipElement.style.display = 'none';
      tooltipElement.style.zIndex = '1000';
      tooltipElement.style.pointerEvents = 'none';

      document.body.appendChild(tooltipElement);

      let isTooltipVisible = false;

      const showTooltip = (event) => {
          if (!isTooltipVisible) {
              let rect = el.getBoundingClientRect();
              let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
              let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
              tooltipElement.style.top = rect.top + scrollTop - tooltipElement.offsetHeight - 40 + 'px';
              tooltipElement.style.left = rect.left + scrollLeft  - 12 + 'px';
              tooltipElement.style.display = 'block';
              isTooltipVisible = true;
          } else {
              tooltipElement.style.display = 'none';
              isTooltipVisible = false;
          }
      };

      const hideTooltip = () => {
          tooltipElement.style.display = 'none';
          isTooltipVisible = false;
      };

      // Event listeners for desktop (mouse)
      el.addEventListener('mouseenter', () => {
          if (!isTooltipVisible) showTooltip();
      });
      el.addEventListener('mouseleave', hideTooltip);

      // Event listeners for mobile (touch)
      el.addEventListener('touchstart', (event) => {
          event.preventDefault();  // Prevent default behavior to avoid triggering other events
          showTooltip();
      });

      // Cleanup on unbind
      el._cleanupTooltip = () => {
          el.removeEventListener('mouseenter', showTooltip);
          el.removeEventListener('mouseleave', hideTooltip);
          el.removeEventListener('touchstart', showTooltip);
          document.body.removeChild(tooltipElement);
      };
  },
  unbind(el) {
      if (el._cleanupTooltip) {
          el._cleanupTooltip();
      }
  }
});


