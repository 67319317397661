<template>
  <div v-if="thereAreProducts && shouldShow" class="component" id="popular-products">
    <div class="title">
      <h1>Trending in {{regionInfo.name}}:</h1>
    </div>
    <div class="product-display" ref="products">
      <product-item
        v-for="product in products"
        v-bind:key="product.item_id"
        v-bind:product="product"
        v-bind:selectedProduct="selectedProduct"></product-item>
    </div>
    <div class="teaser"></div>
  </div>
</template>

<script>
import ProductItem from '@/components/product-item.vue';
import axios from 'axios';


export default {
  name: 'PopularProducts',
  props: ["regionInfo", "selectedItem"],
  components: {
    'product-item': ProductItem,
  },
  data() {
    return {
      selectedProduct: null,
      products: null,
      shouldShow: true,
    };
  },
  mounted() {

  },
  watch: { 
    regionInfo: {
      immediate: true,
      handler (newVal, oldVal) { // watch it
        if (newVal === null) {
          return;
        }

        axios({
          method: 'GET',
          url: 'popular/',
          params: {
            region: newVal,
          },
        }).then((response) => {
          this.searching = false;
          this.products = response.data;
        }).catch((error) => {
          if (error.response.status == 400) {
            // no such region
          }
          this.searching = false;
          this.products = null;
        });

      }
    },
  },
  methods: {
    findRegionForRegionName(regionName) {
      if (!regions) {
        return;
      }
      
      var results = regions.filter(function (entry) { return entry.name === regionName; });
      if (results.length > 0) {
        return results[0];
      } else {
        console.log("Couldn't find " + regionName + " in regions")
        return null;
      }
    },
  },
  computed: {
    thereAreProducts: function() {
      if (this.products) {
        if (this.products.length > 0) {
          return true;
        }
      }
      return false;
    },
    isLoggedIn: function() {
      return this.$store.state.token != "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#popular-products {
  max-width: 1000px;
  background: transparent;
  border: 1px solid #9C665F;
  border-radius: 0 0 20px 20px ;
  margin: 0 auto;
  position: relative;
  top: -50px;
  padding: 1em;
  .title {
    h1 {
      color: white;
      font-size: 36px;
      margin: 30px auto 0 auto;
      font-family: $header-font;
    }
  }
  .product-display {
    display: flex;
    flex-flow: column wrap;
    max-height: 420px;
    overflow-x: auto;
    padding: 15px 35px 15px 15px;
    gap: 5px;
    overflow-x: auto;
  }

  .teaser {
    display: flex;
    align-items: center;
    justify-content: center;


    position: absolute;
    top: 0px;
    right: 0px;

    background:linear-gradient(90deg, transparent 0%, #401d1898 30%, $nav-background 70%);
    width: 100px;
    border-radius: 20px;
    height: 100%;
    font-size: 40px;
    color: white;
  }

}

@media only screen and (max-width: 1130px) {
  #popular-products {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  #popular-products {
    margin-top: 30px;
    padding: 0 0 8px 0;
    .title {
      h1 {
        font-size: 24px;
      }
    }
    .teaser {
      width: 50px;
      background:linear-gradient(90deg, transparent 0%, $nav-background 70%);
    }
  }
}

</style>