<template>
  <div id="bottom-bar" v-if="routeIsHome">
    <div v-if="currentStep.stepNumber == 0 && cart.length > 0" class="step-search footer-bar">
        <button class="big-button" @click="nextStep">{{ buttonLabel }}</button>
    </div>
    <!-- <div v-if="currentStep.stepNumber === 1" class="step-location footer-bar">
        <cart-component></cart-component>
        <h3>{{currentStep}}</h3>
        <div class="footer-right">
            <button class="big-button" @click="nextStep">Confirm location</button>
        </div>
    </div>
    <div v-if="currentStep.stepNumber === 2" class="step-search footer-bar">
        <cart-component></cart-component>
        <h3>{{currentStep}}</h3>
        <div class="footer-right">
            <button class="big-button" @click="nextStep">Let's go girls</button>
        </div>
    </div> -->
  </div>
</template>

<script>
import {STEPS, getNextStep} from '../steps'
export default {
    name: 'bottom-bar',
    methods: {
        nextStep() {
            this.$posthog.capture('continue_to_location', {cart: this.cart.length})
            this.$store.dispatch('incrementStep')
        }
    },
    computed: {
        currentStep() {
            return this.$store.getters['step']
        },
        cart() {
            return this.$store.getters['cart/cartItems']
        },
        buttonLabel() {
            if (this.cart.length === 1) {
                return 'Start one alert'
            } else {
                return `Start alerts for ${this.cart.length} bottles`
            }
        },
        routeIsHome() {
            return this.$route.name === 'home'
        }
    }
}
</script>

<style lang="scss">
#bottom-bar {

    .footer-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - 40px);
        height: 100px;
        background: #5469d4;
        z-index: 100;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 0 20px;
        min-height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-shadow: inset 0px 2px $emphasis;

        & > * {
            flex: 1;
        }

        .big-button {
            max-width: 400px;
            margin: 0 auto;
            font-weight: 700;
        }

        a {
        flex: 1;
        color: black;
        .footer-back {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
          color: #000;
          text-decoration: underline;
          cursor: pointer;

          p {
            color: black;
            text-align: left;
            font-family: Abel;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            text-decoration-line: underline;
          }


          &:before {
            margin-right:4px;
            content: ' ';
            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.98212 11.7614L0.741205 6.57614C0.419597 6.25795 0.419597 5.74206 0.741205 5.42386L5.98212 0.238643C6.30372 -0.0795476 6.82515 -0.0795476 7.14676 0.238643C7.46837 0.556834 7.46837 1.07272 7.14676 1.39092L3.3117 5.18522L14.5 5.18522V6.81478L3.3117 6.81478L7.14676 10.6091C7.46837 10.9273 7.46837 11.4432 7.14676 11.7614C6.82515 12.0795 6.30372 12.0795 5.98212 11.7614Z" fill="black"/></svg>');
            background-size: 14px 14px;
            height: 14px;
            width: 14px;
          }
        }
        }


        .footer-right {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: right;

            .big-button {

            }
        }
    }



    .step_search {

    }
}
</style>