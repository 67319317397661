// ./plugins/posthog.js
export default {
  install(Vue, options) {
    Vue.prototype.$posthog = null; // Set up a placeholder

    const initPosthog = async () => {
      const posthog = (await import("posthog-js")).default;

      const phInstance = posthog.init(
        "phc_BQfpZIyIuMcLtcB4cx2EgKHEzyTj87JpQXZUVZv2AYc",
        {
          api_host: 'https://app.posthog.com',
          person_profiles: 'identified_only',
          capture_pageview: true,
          capture_pageleave: true,
          loaded: function(ph) {
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
              console.log("Disabling capturing for localhost");
              ph.opt_out_capturing();
              ph.set_config({ disable_session_recording: true });
            }
          }
        }
      );

      Vue.prototype.$posthog = phInstance;
    };

    // Immediately initiate loading but in a non-blocking way
    initPosthog();
  }
};
