<template lang="html">
  <div id="confirmed" class="component card">
    <div class='title'>
      <h1>Success!</h1>
      <p>Your alerts will begin momentarily.</p>
    </div>
    <div class="card-content">
      <button class="big-button thicker" @click="startAgain">Start a new alert</button>
      <router-link to="/dashboard" tag="button" class="big-button-alt thicker">Go to dashboard</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmedPayment',
  props: ['product'],
    data: () => ({
    responseMessage: null,
    loading: false,
    shouldShowSubscribeBox: false,
  }),
  mounted() {
    this.scrollToElement();
    this.$store.dispatch('getUser');
    
    if (this.isSubscribed == false) {
      this.shouldShowSubscribeBox = true;
    }
  },
  methods: {
    startAgain() {
      this.$emit('resetCheckout');
    },
  }
}
</script>

<style lang="scss" scoped>

#confirmed {
  background: transparent;

  .title {
    border-radius: 20px 20px 0 0;
    background: white;
  }
  .card-content {
    background: $nav-background;
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #6F5326;
    border-top: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;

  }
}


.inside-card {
  background: white;

  h3 {
    margin-top: 0em;
  }

  p {
    &.response-message {
      font-size: 14px;
    }
  }


  label {
    font-weight: 700;
    text-decoration: underline;
    color: $link;
    cursor: pointer;
  }
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #FFF;
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .575em;
    left: .18em;
    font-size: 1.375em;
    color: $link;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2);
  }




}
</style>
