<template>
  <div class="component card" id="product-search">
    <div id="searcharea">
      <form @submit.prevent="search" ref="searchtextbox">
        <div class="search-title">
          <div class="searchbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0464 9.80256C17.0464 13.5677 14.0053 16.6051 10.2722 16.6051C6.53914 16.6051 3.49805 13.5677 3.49805 9.80256C3.49805 6.0374 6.53914 3 10.2722 3C14.0053 3 17.0464 6.0374 17.0464 9.80256ZM15.512 18.079C13.9976 19.0453 12.2001 19.6051 10.2722 19.6051C4.87409 19.6051 0.498047 15.2164 0.498047 9.80256C0.498047 4.38876 4.87409 0 10.2722 0C15.6703 0 20.0464 4.38876 20.0464 9.80256C20.0464 12.1971 19.1903 14.3912 17.7682 16.0936L23.9824 22.3511L21.8684 24.4797L15.512 18.079Z" fill="black"/>
            </svg>
            <input v-model="searchterms" :placeholder="searchHelperText" >
            <a v-if="searchterms && !(searchterms === '')" @click.stop="resetSearch">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
            </a>
            <svg width="10" height="10" v-else></svg>
          </div>
          <div class="region-selection">
            <p>Include local results from </p>
            <div class="region-select">
              <v-select :options="regions" label="name" v-model="selectedRegion" key="region" placeholder="Type or select a region" :filter-by="regionFilter">
                <template slot="selected-option" slot-scope="option">
                    <span>{{ option.name }}</span>
                </template>
                <template #option="{ name, country }">
                  {{ name }}, {{ country }}
                </template>
              </v-select>
            </div>
          </div>
          <button class="big-button">Search</button>
        </div>
      </form>
    </div>
    <div v-if="searching && !errorMessage" class="loader"></div>
    <div v-if="errorMessage" class="error">
      <h3>{{errorMessage}}</h3>
      <button class='small-button' @click="resetSearch">Reset search</button>
    </div>
    <div v-if="results" class="results">
      <h3 v-if="results.length == 0">No results for <em>{{lastsearchterms}}</em></h3>
      <h3 v-else>{{ results.length }} results</h3>
      <div v-if="results.length > 0" class="product-display" ref="products">
        <product-item
          v-for="result in results"
          v-bind:key="uniqueIdForCartProduct(result)"
          v-bind:product="result"></product-item>
      </div>
    </div>
    <div v-if="!results && currentStep == 0 && collections.length > 0 && !searching" id="collections-container">
      <h1>Popular Selections</h1>
      <div id="collections">
        <collection-item v-for="collection in collections" v-bind:key="collection.name" v-bind:collection="collection"></collection-item>
      </div>
    </div>
    <!-- <div v-if="selectedProduct" class="card-footer">
      <button class="big-button">Continue with X products</button>
    </div> -->
  </div>
</template>

<script>
import helperMixin from '@/mixins/helperMixin';
import axios from 'axios';
import ProductItem from '@/components/product-item.vue';
import CollectionItem from '@/components/CollectionItem.vue';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// import { COLLECTIONS } from '../collections.js';

function initialData () {
  return {
    searchterms: null,
    lastsearchterms: null,
    searching: false,
    results: null,
    regionUnavailable: null,
    hideModal: false,
    errorMessage: null,
    startTime: Date.now(),
    collections: [],
  };
}

export default {
  name: 'ProductSearch',
  props: ['regionInfo'],
  mixins: [helperMixin],
  components: {
    'product-item': ProductItem,
    'collection-item': CollectionItem,
    'v-select': vSelect,
  },
  data() {
    return {
      ...initialData(),
      collections: [],
    };
  },
  mounted() {
    this.$store.dispatch('regions/initializeRegions');
    this.selectedRegion = this.findRegionForGeoRegionInfo(this.regionInfo);
    this.hideModal = false;
    this.regionUnavailable = null;
    this.getCollections();
  },
  watch: {
    regionInfo: {
      immediate: true,
      handler(newVal) {
        this.selectedRegion = this.findRegionForGeoRegionInfo(newVal);
        this.hideModal = false;
        this.regionUnavailable = null;
      },
    },
    selectedRegion: {
      immediate: true,
      handler(newVal) {
        this.results = null;
        this.$emit('didSelectRegion', newVal);
      },
    },
  },
  computed: {
    selectedRegion: {
      get() {
        return this.$store.getters['regions/selectedRegion'];
      },
      set(value) {
        this.$store.dispatch('regions/setSelectedRegion', value);
      },
    },
    regions() {
      return this.$store.getters['regions/regions'];
    },
    currentStep() {
      return this.$store.getters['step'].stepNumber;
    },
    isLoggedIn() {
      return this.$store.state.token !== '';
    },
    searchHelperText() {
      return "Enter a bottle name or ID";
    },
  },
  methods: {
    findRegionForGeoRegionInfo(regionInfo) {
      return this.$store.getters['regions/findRegionForGeoRegionInfo'](regionInfo);
    },
    getCollections() {
      axios
        .get('popular/collections/', {
          params: { region: this.selectedRegion },
        })
        .then((response) => {
          this.collections = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      if (!this.searchterms) return;

      if (this.$store.state.token == "") {
        if (window.fathom) {
          window.fathom.trackGoal('VH3QFX2V', 0);
        }

        if (window.rdt) {
          window.rdt('track', 'Search');
        }
      }

      this.lastsearchterms = this.searchterms;
      this.results = null;
      this.errorMessage = null;
      this.searching = true;

      axios
        .get('search/', {
          params: {
            search_terms: this.searchterms,
            region: this.selectedRegion,
          },
        })
        .then((response) => {
          this.searching = false;
          this.results = response.data;

          this.$nextTick(() => {
            this.$refs.products.scrollIntoView({
              block: 'end',
              scrollBehavior: 'smooth',
            });
          });
          this.$posthog.capture('search', { 'searchterms': this.searchterms, 'results': this.results.length, 'region': this.selectedRegion });
        })
        .catch((error) => {
          console.log(error);
          this.searching = false;
          if (error.response) {
            if (error.response.status === 404) {
              this.regionUnavailable = true;
            } else if (error.response.status === 400) {
              this.errorMessage = error.response.data.message;
            } else {
              this.regionUnavailable = false;
            }
          } else if (error.request) {
            this.errorMessage =
              'We ran into a problem with your search and have been notified!';
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    resetSearch() {
      this.searchterms = null;
      this.lastsearchterms = null;
      this.searching = false;
      this.results = null;
      this.errorMessage = null;
    },
    regionFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return option.name.toLowerCase().indexOf(temp) > -1 || 
      option.countryName.toLowerCase().indexOf(temp) > -1
    },
  },
};
</script>

<style lang="scss">

#product-search {
  z-index: 20;
  position: relative;
  display: flex;
  flex-flow: column wrap;

  #searcharea {
  display: flex;
  flex-flow: column;
  width: 100%;

  h1 {
    max-width: 60%;
    margin: auto;
  }
  h3 {
    margin: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }



  .search-title {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 16px;
    border-bottom: none;
    padding: 24px;
    max-width: 750px;
    margin: 0 auto;
    
    .region-selection {
      display: flex;
      flex-flow: column;
      flex: 0 1 auto;
      justify-content: left;
      align-items: center;
      gap: 4px;
      .region-select {
        width: 100%;
      }

      p {
        margin: 0;
      }
    }
    .searchbox {
      display: flex;
      align-items: center;
      background: white;
      padding: 0 0.5em;
      border-radius: 12px;
      gap: 10px;
      flex-grow: 3;
      // min-width: 300px;

      svg {
        padding-left: 0px;
      }

      input {
        text-align: left;
        color: #000;
        background-color: transparent;
        font-family: Assistant;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        height: 74px;
        width: 100%;
        min-width: 450px;
        padding: 0;
        outline:none;
        text-overflow: ellipsis;
        border-style: none;
        &::placeholder {
          color: black;
          font-style: italic;
          font-family: Assistant;
          text-overflow: ellipsis;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 130% */
        }
      }
    }

    .big-button {
      flex-grow: 1;
    }
  }

}

}

div {
  &.error {
    margin-top: 1em;
    margin-bottom: 1em;
    display: inline-block;
    h1 {
      color: red;
    }
    button {
      margin-top: 1em;
    }
  }
}

span.vs__selected {
            position: static !important;
          }

#collections-container {
  margin: 0 0 1em 0;
  padding: 0;
  background-color:#5e5366;

  box-shadow: inset 0px 5px 10px 0 rgb(73 69 69 / 55%);
  z-index: 2;
  width: 100%;
  
  > h1:first-child {
    padding: 0em;
    text-align: center;
    color: #ffffff;
    font-family: $header-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }
}

#collections {
  padding: 0 1em 1em 1em;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 10px;
}

.card-footer {
    display: flex;
    border-top: none;
    justify-content: center;
    align-items: center;
  }


#product-search {
  margin-top: 6em;
  background-color: $search-card-background;
  .small-button {
    margin-bottom: 0.5em;
  }
  .results {
    margin-top: 0;
    padding: 0 12px 12px 12px;

    h3 {
      margin: 0 1em;
      text-align: center;
      color: #401D18;
      font-family: Assistant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .product-display {
      display: flex;
      flex-flow: column wrap;
      max-height: 400px;
      overflow-x: auto;
      padding: 14px;
      gap: 14px;
      // height: 120%;
      // width: 100%;
    }
  }
}


@media only screen and (max-width: 600px) {

  .search-title {
    padding: 4px 24px 24px 24px !important;

  .searchbox {
    min-width: none;
    input {
      min-width: unset;
    }
  }
}
  #product-search {
    margin: 4em 15px 0 15px;
    &.card{
      max-width: 100%;
    }

    .results {
      .product-display {
        gap: 4px;
      }
    }

  }
}

</style>
