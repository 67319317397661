<template>
  <div class="component card" id="payment">
    <div v-if="paymentMethod && !overwritePaymentMethod && !newPaymentMethod">
      <div class="title">
        <h1 v-if="subscriptionTier || upgradeInfo">Pay for your subscription</h1>
        <h1 v-else-if="invoiceNeedingPayment">Pay for your invoice</h1>
        <h1 v-else>Pay for your alert</h1>
        <p class="pricing-description" v-if="prorationAmount"><em>{{prorationPriceString}}</em>, and continue at <em>{{displayPrice}}</em>{{ couponDurationMessage }}.</p>
        <p class="pricing-description" v-else>You'll pay <em>{{displayPrice}}</em>{{ couponDurationMessage }}.</p>
        <p v-if="!submittingPayment && !newPaymentMethod">We'll charge your {{ cardName }} ending in {{ cardLast4 }}. <a @click="setupPaymentForm(true)">Use a different card.</a></p>
        <p class="stripe-link">Payments are processed securely by <a href="https://www.stripe.com" target="_blank">Stripe</a>.</p>
      </div>
      <form @submit.prevent="submitPayment" id="payment-form">

        <p id="card-error" role="alert">{{ paymentStatusError }}</p>
        <div v-if="submittingPayment" class="loader"></div>
        <button :disabled=submittingPayment class="big-button thicker" v-else>{{buttonMessage}}</button>
      </form>
    </div>
    <div v-else>
      <div class="title">
        <h1 v-if="subscriptionTier || upgradeInfo">Pay for your subscription</h1>
        <h1 v-else>Pay for your alert</h1>
        <p class="pricing-description" v-if="prorationAmount"><em>{{prorationPriceString}}</em>, and continue at <em>{{displayPrice}}</em>{{ couponDurationMessage }}.</p>
        <p class="pricing-description" v-else>You'll pay <em>{{displayPrice}}</em>{{ couponDurationMessage }}.</p>
        <p class="stripe-link">Payments are processed securely by <a href="https://www.stripe.com" target="_blank">Stripe</a>.</p>
      </div>
      <form @submit.prevent="submitPayment" id="payment-form">

        <input id="card-holder-name" type="text" v-model="name" :disabled='submittingPayment' placeholder="Your full name">
        <div v-if="showPaymentForm" class="stripe-container">
          <div id="card-element"><!--Stripe.js injects the Card Element--></div>
        </div>

        <label v-if="!showPaymentForm" for="email">Email for your receipt</label>
        <input id="email" type="text" v-model="email" :disabled='submittingPayment' placeholder="Email for your receipt">
        <p id="card-error" role="alert" v-if="paymentStatusError">{{ paymentStatusError }}</p>
        <div v-if="submittingPayment" class="loader"></div>
        <button :disabled=submittingPayment class="big-button thicker" v-else>{{ buttonMessage }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "StripePayment",
  props: ["subscriptionTier", "clientSecret", "pricingRegion", "amount", "total", "customerId", "radius", "coordinates", "upgradeInfo", "subscriptionCoupon", "invoiceNeedingPayment"],
  data: () => ({
    stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
    stripe: null,
    elements: null,
    card: null,
    newPaymentMethod: null,
    name: '',
    email: '',
    submittingPayment: false,
    successfulPayment: false,
    pendingSubscription: null,
    paymentStatusError: '',
    invoiceNeedingRetry: null,
    overwritePaymentMethod: false,
  }),
  mounted() {
    this.scrollToElement();
    if (this.invoiceNeedingPayment) {
      this.setupPaymentForm(true);
    } else {
      this.setupPaymentForm(false);
    }

    this.email = this.userEmail;
  },
  methods: {
    configureStripe() {
      this.stripe = Stripe(this.stripePublicKey);
      if (!this.paymentMethod || this.overwritePaymentMethod) {
        this.elements = this.stripe.elements();

        var style = {
          base: {
            color: "black",
            fontFamily: 'Assistant, AvenirNext-Regular, Lato-Light, Helvetica, Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
              color: "#535a66"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        };

        this.card = this.elements.create('card', {style: style});
        this.card.mount('#card-element');
      }
    },
    submitPayment() {
      if (this.submittingPayment === true) {
        this.paymentStatusError = "Just a sec – the payment hasn't finished processing.";
        return;
      }

      if (this.email === "") {
        this.paymentStatusError = "You need to add an email.";
        return;
      } else {
        this.paymentStatusError = "";
      }

      if (!this.stripe) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      this.submittingPayment = true;

      if (this.invoiceNeedingRetry != null || this.invoiceNeedingPayment != null) {
        // retrying an invoice
        let invoiceId = this.invoiceNeedingPayment ? this.invoiceNeedingPayment.id : this.invoiceNeedingRetry;

        // first, set the subscription you're retrying or checking out
        let sub = this.pendingSubscription ? this.pendingSubscription : this.subscription;
        let priceId = this.pendingSubscription ? this.pendingSubscription.items.data[0].price.id :  this.subscription.plan_info.id;
        
        this.retryInvoice(sub, priceId, invoiceId);
      } else if (this.subscriptionTier != null) {
        // it's a subscription payment
        this.checkoutSubscription(this.subscriptionTier.id);

        if (window.rdt) {
          window.rdt('track', 'Purchase', {
            value: this.subscriptionTier.subtotal,
            currency: "CAD"
          });
        }

        if (window.fathom) {
          window.fathom.trackGoal('IYYTCDJB', this.subscriptionTier.subtotal);
        }

        this.$posthog.capture('purchase', { 'purchasetype': 'new-subscription', 'amount': this.subscriptionTier.subtotal,});
      } else if (this.upgradeInfo != null) {
        this.$posthog.capture('purchase', { 'purchasetype': 'upgrade-subscription', 'amount': this.upgradeInfo.next_tier.subtotal,});
        this.checkoutSubscription(this.upgradeInfo.next_tier.id);
      } else if (this.clientSecret != null) {
        // it's a by-the-bottle payment
        if (window.fathom) {
          window.fathom.trackGoal('VMMIHAEJ', this.amount);
        }

        if (window.rdt) {
          window.rdt('track', 'Purchase', {
            value: this.amount,
            currency: "CAD"
          });
        }
        this.$posthog.capture('purchase', { 'purchasetype': 'btb', 'amount': this.subscriptionTier.subtotal,});
        this.checkoutByTheBottle();
      }
    },
    checkoutSubscription(priceId) {
      // setup payment method
      var data = {
        customerId: this.customerId,
        paymentMethodId: this.paymentMethod,
        priceId: priceId,
        email: this.email,
      };

      if (this.subscriptionCoupon) {
        data.coupon = this.subscriptionCoupon.id;
      }

      if (this.subscription) {
        data.existingSubscription = this.subscription.id;
      }

      if (this.overwritePaymentMethod || !this.paymentMethod) {
        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.name,
            email: this.email,
          }
        }).then((result) => {
          if (result.error) {
            // errors!
            throw result.error;
          } else {
            // payment method successfully created
            this.newPaymentMethod = result.paymentMethod;
            
            // now pay

            var data = {
              customerId: this.customerId,
              paymentMethodId: this.newPaymentMethod.id,
              priceId: priceId,
              email: this.email,
            };

            if (this.subscriptionCoupon) {
              data.coupon = this.subscriptionCoupon.id;
            }

            if (this.subscription) {
              data.existingSubscription = this.subscription.id;
            }
            
            if (this.upgradeInfo) {
              this.putSubscription(data);
            } else {
              // we're checking out a new subscription
              this.postSubscription(data);
            }
          }
        })
        .catch((error) => {
          if (error.type === "card_error") {
            this.paymentStatusError = error.message;
            this.setupPaymentForm(true);
          } else {
            console.log(error);
            this.paymentStatusError = error.message;
            this.submittingPayment = false;
          }

        });
      } else if (this.paymentMethod) {
        // it will use an existing payment method that's saved
        if (this.upgradeInfo) {
          this.putSubscription(data);
        } else {
          this.postSubscription(data);
        }
      } else {
        // we don't have what we need
        this.submittingPayment = false;
        return;
      }
    },
    checkoutByTheBottle() {
      this.submittingPayment = true;


      var data = {}
      if (this.paymentMethod) {
        data.payment_method = this.paymentMethod;
        data.receipt_email = this.email;
      } else if (this.elements != null) {
        data.payment_method = {
          card: this.card,
          billing_details: {
            name: this.name,
          }
        }
        data.receipt_email = this.email;
      }

      this.stripe.confirmCardPayment(this.clientSecret,
        data)
      .then( (result) => {
        this.submittingPayment = false;
        if (result.error) {
          throw result.error;
        } else if (result.paymentIntent.status === 'succeeded') {
          this.successfulPayment = true;
          this.submittingPayment = false;
          this.$emit('paid')
          return data;
        }
      })
      .catch((error) => {
        this.submittingPayment = false;

        if (error.type === "card_error") {
          this.paymentStatusError = error.message;
        } else {
          console.log(error);
          this.paymentStatusError = error.message;
        }
      });

    },
    retryInvoice(subscription, priceId, invoiceId) {
      // we'll retry the invoice! Yay!

      // first, we need a new payment method
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.name,
          email: this.email,
        }
      }).then((result) => {
        if (result.error) {
          // errors!
          throw result.error;
        }
        // payment method successfully created
        return result.paymentMethod;
      })
      // now send the new payment method off to be paid
      .then((paymentMethod) => {
        this.newPaymentMethod = paymentMethod;
        // now send to the server
        var data = {
          subscriptionId: subscription.id,
          priceId: priceId,
          paymentMethodId: this.newPaymentMethod.id,
          invoiceId: invoiceId,
          email: this.email,
        };
        this.retryInvoiceWithNewPaymentMethod(data);
      })
      .catch((error) => {
        if (error.type === "card_error") {
          this.paymentStatusError = error.message;
        } else {
          console.log(error);
          this.paymentStatusError = error.message;
        }

        this.setupPaymentForm(true);
      });
    },
    postSubscription({ customerId, paymentMethodId, priceId, email, coupon }) {
      axios.post('checkout_subscription/', {
        payment_method: paymentMethodId,
        price_id: priceId,
        cart: this.cartItems,
        radius: this.radius,
        coordinates: this.coordinates,
        region: this.pricingRegion,
        email: email,
        coupon: coupon,
      }).then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          console.log("throwing error: ", result.error);
          throw result;
        } else if (result.data.error) {
          console.log("throwing other error: ", result.data.error);
          throw result.data.error;
        }

        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result.data.stripe_subscription,
        };
      }).then(this.handlePaymentThatRequiresCustomerAction)
      .then(this.handleRequiresPaymentMethod)
      .then(this.onSubscriptionComplete)
      .catch((error) => {
        console.log("Payment server Error: ", error);
        this.submittingPayment = false;
        this.setErrorMessage(error);
        
        // now handle resetting the payment method if it's new
        if (this.newPaymentMethod) {
          this.newPaymentMethod = null;
          // this.setupPaymentForm(false)
        }

        throw error;
      });
    },
    putSubscription({ customerId, paymentMethodId, priceId, email, existingSubscription }) {
      axios.put('checkout_subscription/', {
        payment_method: paymentMethodId,
        price_id: priceId,
        cart: this.cartItems,
        radius: this.radius,
        coordinates: this.coordinates,
        region: this.pricingRegion,
        email: email,
        existing_subscription: existingSubscription,
      }).then((result) => {
        this.submittingPayment = false;

        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }

        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result.data.stripe_subscription,
        };
      }).then(this.handlePaymentThatRequiresCustomerAction)
      .then(this.handleRequiresPaymentMethod)
      .then(this.onSubscriptionComplete)
      .catch((error) => {
        this.submittingPayment = false;
        this.setErrorMessage(error);
      });
    },
    retryInvoiceWithNewPaymentMethod({
    subscriptionId,
    priceId,
    paymentMethodId,
    invoiceId,
    email}) {
      axios.post('retry_invoice', {
          subscription_id: subscriptionId,
          price_id: priceId,
          payment_method_id: paymentMethodId,
          invoice_id: invoiceId,
          email: email
        }).then((response) => {
          return response.data;
        }).then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete)
        .catch((error) => {
          console.log(error);

          this.submittingPayment = false;
          this.setErrorMessage(error);
        });
    },
    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
    }) {
      if (subscription && subscription.status === 'active' && !subscription.pending_update) {
        // A new subscription is active, no customer actions required.
        return { subscription, invoice, paymentMethodId, priceId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        return this.stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              if (result.error.code) {
                // Store the latest invoice status.
                if (subscription?.latest_invoice) {
                  this.invoiceNeedingRetry = subscription.latest_invoice.id;
                  this.pendingSubscription = subscription;
                }
                this.setupPaymentForm(true);
              }
              throw result.error;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
        
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          })
      } else {
        // No customer action needed.
        return { subscription, invoice, paymentMethodId, priceId };
      }
    },
    handleRequiresPaymentMethod({
      subscription,
      invoice,
      paymentMethodId,
      priceId,
    }) {
      if (subscription.status === 'active' && !subscription.pending_update) {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
      ) {
        // Store the latest invoice status.
        this.invoiceNeedingRetry = subscription.latest_invoice.id;
        this.pendingSubscription = subscription;

        this.setupPaymentForm(true);
        
        throw { message: 'Your card was declined. Please try a different one.' };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    onSubscriptionComplete(result) {
      // Payment was successful.

      if (result.subscription?.status === 'active') {
        // if we have checked out a new sub... or maybe upgrade, too
        // `result.subscription.items.data[0].price.product`
      }

      this.submittingPayment = false;
      this.successfulPayment = true;
      this.$emit('paid');
    },
    setErrorMessage(error) {
      if (error.response) {
        this.paymentStatusError = error.response.data.error;
      } else if (error.message) {
        this.paymentStatusError = error.message;
      } else {
        console.log("Unhandled error: ", error)
        this.paymentStatusError = "Hm, looks like there's been an error. We're looking into it."
      }
    },
    setupPaymentForm(shouldOverwrite) {
      this.overwritePaymentMethod = shouldOverwrite;

      this.submittingPayment = false;
      this.$nextTick(() => {
        this.configureStripe();
      });
    },
  },
  
  computed: {
    cartItems: function() {
      return this.$store.getters['cart/cartItems'];
    },
    showPaymentForm: function() {
      if (this.newPaymentMethod) {
        return true;
      } else if (this.overwritePaymentMethod) {
        return true;
      } else if (this.upgradeInfo) { // we're checking out an inline upgrade of the subscription
        if (this.upgradeInfo.next_tier.total === 0) {
          // it's a free sub!
          return false;
        } else {
          return true;
        }
      } else if (this.subscriptionTier != null) { // we're checking out a new subscription
        if (this.subscriptionTier.total === 0) {
          // it's a free sub!
          return false;
        } else {
          return true;
        }
      } else if (this.amount != null) { // really just for the BTB alerts
        return true;
      } else {
        return true;
      }
    },
    couponDurationMessage: function() {
      var subMessage = '';
      if (this.subscriptionCoupon) {
        if ('duration_in_months' in this.subscriptionCoupon && this.subscriptionCoupon.duration_in_months > 0) {
          subMessage = " for the next " + this.subscriptionCoupon.duration_in_months + " months, then continue at full price. You can cancel anytime";
        }
      }
      return subMessage;
    },
    displayPrice: function () {
      if (this.upgradeInfo) { // we're checking out an inline upgrade of the subscription
        let priceInDollars = this.upgradeInfo.next_tier.subtotal / 100;
        return "$"+ priceInDollars.toFixed(2) + this.taxAmount + " / " + this.upgradeInfo.next_tier.billing_period;
      } else if (this.subscriptionTier != null) { // we're checking out a new subscription
        let priceInDollars = this.subscriptionTier.subtotal / 100;
        return "$"+ priceInDollars.toFixed(2) + this.taxAmount + " / " + this.subscriptionTier.billing_period;
      } else if (this.amount != null) { // really just for the BTB alerts
        let priceInDollars = this.amount / 100;
        return "$"+ priceInDollars.toFixed(2);
      } else {
        return "No available price";
      }
    },
    taxAmount: function() {
      if (this.upgradeInfo) { // we're checking out an inline upgrade of the subscription
        let taxesInDollars = this.upgradeInfo.next_tier.tax_amount / 100;
        return " + $"+ taxesInDollars.toFixed(2) + " HST";
      } else if (this.subscriptionTier != null) { // we're checking out a new subscription
        let taxesInDollars = this.subscriptionTier.tax_amount / 100;
        return " + $"+ taxesInDollars.toFixed(2) + " HST";
      } else if (this.amount != null) { // really just for the BTB alerts
        return "";
      } else {
        return "";
      }
    },
    buttonMessage: function() {
      if (this.upgradeInfo) {
        // calculate proration price
        if (this.upgradeInfo.total <= 0) {
          return "Confirm change to " + this.upgradeInfo.next_tier.name + " tier";
        } else {
          return "Pay Now";
        }
      } else {
        return "Pay Now";
      }
    },
    prorationPriceString: function() {
      if (!this.upgradeInfo) {
        return null;
      }

      var prorationPriceInDollars = this.prorationAmount / 100;

      if (prorationPriceInDollars > 0) {
        let prorationString = "$" + prorationPriceInDollars.toFixed(2);
        return "Pay " + prorationString + " now";
      } else if (prorationPriceInDollars == 0) {
        let prorationString = "$" + prorationPriceInDollars.toFixed(2);
        return "Pay nothing now";
      } else {
        prorationPriceInDollars = Math.abs(prorationPriceInDollars);
        let prorationString = "$" + prorationPriceInDollars.toFixed(2);
        return "Get a credit of " + prorationString + " on your next bill";
      }
    },
    prorationAmount: function() {
      if (this.upgradeInfo) {
        return this.upgradeInfo.total;
      } else {
        return null;
      }
    },
    paymentMethod: function() {
      if (this.newPaymentMethod != null) {
        return this.newPaymentMethod.id;
      } else if (this.$store.state.stripeAccount.payment_method != null) {
        return this.$store.state.stripeAccount.payment_method.id;
      }  else {
        return null;
      }
    },
    userEmail: function() {
      return this.$store.state.email;
    },
    cardName: function() {
      if (this.newPaymentMethod) {
        return this.newPaymentMethod.card.brand;
      } else if (this.$store.state.stripeAccount.payment_method) {
        return this.$store.state.stripeAccount.payment_method.safe_payment_info.brand;
      } else {
        return null;
      }
    },
    cardLast4: function() {
      if (this.newPaymentMethod) {
        return this.newPaymentMethod.card.last4;
      } else if (this.$store.state.stripeAccount.payment_method) {
        return this.$store.state.stripeAccount.payment_method.safe_payment_info.last4;
      } else {
        return null;
      }
    },
    subscription () {
      return this.$store.state.stripeAccount.subscription;
    },
  },
}
</script>

<style scoped lang="scss">
#payment {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
    h1 {
      margin: 0;
    }
    .pricing-description {
      font-size: 20px;
      margin: 0;
      
      em {
        // color: $payment-button;
        background-color:white;
        text-decoration: none;
      }
    }

    .stripe-link {
      margin: 0;
      a {
        color: $payment-button;
        text-decoration: none;
        font-weight: 600;
      }

    }
  }
  p {
    margin-bottom: 1em;
    text-align: center;
    // color: white;
  }
  #payment-form {
    width: 60%;
    min-width: 450px;
    max-width: 610px;

    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 2em 0 12px 0;
    .stripe-container {
      width: 100%;
    }
  }

  label {
    padding:10px;
    display: block;
  }

  input {
    border-radius: 12px;
    padding: 0 24px;

    height: 74px;
    border: none;
    font-size: 16px;
    width: calc(100% - 48px); // 24 px is the padding
    background: white;

    text-align: left;
    font-size: 20px;
    color: black;

    &::placeholder {
      color: #535a66;
    }
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  #card-error {
    color: $red;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    margin-top: 12px;
  }
  #card-element {
    border-radius: 6px;
    padding: 25px 12px;
    border: none;
    width: calc(100% - 24px);
    background: white;
    position: relative;
    padding

    div {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .big-button {
    width: 300px;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: $payment-button;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: $payment-button;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
    #payment-form {
      // width: 200px;
      min-width: 200px;
      width: auto !important;
      border-radius: 0 0 8px 8px;
      margin: 0 10px;
    }
  }
}
</style>
