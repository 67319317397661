<template>
  <div id="find-product" class="card component">
    <div class="title">
      <h1>Start a new stock alert for...</h1>
    </div>
    <div class="product-display" ref="products" v-if="products.length > 0">
      <product-item 
        v-for="product in products"
        v-bind:key="product.uniqueId"
        v-bind:product="product"
        v-bind:selectedProduct="product"/>
    </div>
    <div v-else class="loader"></div>
    <p><a type="button" class="small-button" @click="resetCheckout">Add another bottle?</a></p>
  </div>
</template>

<script>
import ProductItem from '@/components/product-item.vue';

export default {
  name: 'FindProduct',
  components: {
    'product-item': ProductItem,
  },
  data() {
    return {
      searching: true,
    };
  },
  methods: {
    resetCheckout() {
      this.$emit('resetCheckout');
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.state.token != "";
    },
    products: function() {
      return this.$store.getters['cart/cartItems'];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}




#find-product {
  padding-top: 1em;
  margin-top: 300px;
  // background-color: $search-card-background;

  .title {
    margin-bottom: 5px;
    h1 {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      color: black;
    }
  }
  .product-display {
    display: flex;
    justify-content: center;

    padding: 0 0.5em;
    // height: 120%;
    // width: 100%;
    .product {
      .product-metadata {
        h1 {
        text-decoration: none;
      }

      }
    }
  }
  .small-button {
    margin: 2em;
    &:hover {
      background: #FFFFFF;
      color: #000000;
      transition:all 0.3s ease;
      cursor: pointer;
    }

    &.selected {
      background: #00818D;
    }

    &.red {
      background: #820000;

      &:hover {
        background: #fd0000;
        color: #FFFFFF;
        transition:all 0.3s ease;
        cursor: pointer;
      }

      &.selected {
        background: #fd0000;
      }
    }
    &.blue {
      background: #00A3B3;

      &:hover {
        background: #00818D;
        color: #FFFFFF;
        transition:all 0.3s ease;
        cursor: pointer;
      }

      &.selected {
        background: #820000;
      }
    }
  }


  .not-ontario {
    p {
      // em {
      //   font-style: normal;
      //   font-weight: 600;
      //   text-decoration: underline;
      // }
    }
  }
}


@media only screen and (max-width: 600px) {
  #find-product {
    .title {
      h1 {
        font-size: 24px;
      }
    }
    &.card{
      max-width: 100%;
    }
  }
}

</style>
