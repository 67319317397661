// src/mixins/helperMixin.js
export default {
    methods: {
      uniqueIdForCartProduct(item) {
        // bbid, but if not, then just item_id+retailer_id
        let uniqueId = item.bbid ? item.bbid : item.item_id + item.retailer_id;
        // check that it's a string or number:
        if (typeof uniqueId !== 'string' && typeof uniqueId !== 'number') {
          // raise an error
            throw new Error('uniqueIdForCartProduct: uniqueId is not a string or number');
        }
        // now cast as a string, prepend with bb, and return:
        return 'bb' + uniqueId.toString();
      },
    },
  };
  