<template>
  <div class="product" @click="selectProduct" v-bind:class="{select: isInCart}">
    <div class="product-image">
      <img :src="imageUrl"/>
    </div>
    <div class="product-metadata">
      <h1>{{ product.name }}</h1>
      <p>{{ displayMetadata }}</p>
      <p v-if="isInAlerts">Already watching</p>
      <p v-if="isInCart">Selected for alert</p>
    </div>
    <div class="cart-button">
      <button class="circle-button" v-on:click.prevent="" v-bind:class="{select: isInCart}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'product-item',
  props: ['product'],
  data() {
    return {
      buttonMessage: "Select me"
    };
  },
  methods: {
    selectProduct() {
      if (this.isInCart) {
        this.buttonMessage = "Select me";
        this.$store.dispatch('cart/removeFromCart', this.product);
      } else {
        this.buttonMessage = "Selected!"
        this.$store.dispatch('cart/addToCart', this.product);
      }
    },
  },
  computed: {
    isInCart() {
      return this.$store.getters['cart/isProductInCart'](this.product);
    },
    isInAlerts() {
      return this.$store.getters['isProductInAlerts'](this.product);
    },
    imageUrl: function() {
      if (this.product) {
        if (this.product.image_url) {
          let imageUrl = this.product.image_url;
          if (imageUrl.startsWith("http")) {
            return imageUrl;
          }
          return process.env.VUE_APP_MEDIA_BASE_URL + this.product.image_url;
        } else {
          return process.env.VUE_APP_MEDIA_BASE_URL + "products/thumb.png";
        }
      } else {
        return "";
      }
    },
    stockMessage: function () {
      if (this.product.availability === "ONLINE_ONLY") {
        return "Online only";
      } else if (this.product.availability === "BOTH") {
        return "Retail & online";
      } else if (this.product.availability === "STORE_ONLY") {
        return "Retail only";
      } else {
        return "";
      }
    },
    displayMetadata: function () {
      var metadata = {
        "size": this.displaySize,
        "price": this.displayPrice,
        "regionId": this.displayId,
      };

      var keys = Object.keys(metadata).filter(key => metadata[key] !== null);
      var description = "";

      keys.forEach((key, index) => {
        if (index < keys.length - 1) {  // Check if it's not the last element
          description += metadata[key] + " | ";
        } else {
          description += metadata[key];
        }
      });

      return description;
    },
    displayPrice: function () {
      let price = this.product.price;

      if (price) {
        let priceInCents = price;
        let priceInDollars = priceInCents / 100;
        return "$"+ priceInDollars.toFixed(2);
      } else {
        return null;
      }

    },
    displaySize: function() {
      let size = this.product.product_size;
      if (size) {
        // check if it's an int
        if (Number.isInteger(size)) {
          if (size > 1000) {
            return size / 1000 + "L";
          } else {
            return size + "mL";
          }
        } else {
          return size;
        }
      } else {
        return null;
      }
    
    },
    displayId: function() {
      let id = this.product.item_id;
      if (id) {
        return `${this.product.retailer} ID: ${id}`;
      } else {
        return null;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.product {
  width: 490px;
  height: 170px;
  
  border-radius: 8px;
  border: 1px solid #000;
  background: #FFF;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  
  cursor: pointer;

  &.select {
    background: #863B33;
    transition:all 0.3s ease;
    border: 1px solid $emphasis;

    .product-image {
      outline: 1px solid $emphasis;
    }

    .product-metadata {
      h1 {
        color: white;
        text-decoration: underline;
        text-decoration-color: $emphasis;
      }

      p {
        color: white;
      }
    }

  }


  .product-image {
    // width: 100%;
    height: 100%;
    width: 80px;
    border-radius: 8px;
    background: white;
    padding: 0 0.2em;
    display: flex;
    justify-content: center;
    // overflow: hidden;

    img {
      padding: 2px 0;
      height: calc(100% - 4px);
      // width: 80px;
      // min-width: 80px;
      max-width: 80px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .product-metadata {
    // where the product metadata lives
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 0;
    h1 {
      text-align: left;
      color: black;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 130% */
      margin: 0;
    }

    p {
      text-align: left;
      color: var(--Secondary-gray, #999FAA);
      font-family: Assistant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      margin: 0;
    }


  }

  .cart-button {
    margin-right: 1em;

    @keyframes blue-pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(0,0,0,2)
      }

      50% {
        box-shadow: 0 0 0 0 rgba(0,0,0,2)
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0,0,0,0)
      }
    }

      .circle-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #FF5722; /* Adjust color as needed */
            border: none;
            border-radius: 50%;
            cursor: pointer;
            svg {
                width: 24px;
                height: 24px;
                color: black;
            }
            &.select {
              animation-name: blue-pulse;
              animation-duration: 2s;
              animation-iteration-count: 3;
              animation-delay: 0s;

              svg {
                color: red;
                rotate: 45deg;
                }
              background-color: pink;
            }
        }
    }


  &:hover {
    transition: all 0.3s ease;

    border: 1px solid yellowgreen;
    .product-image {
      outline: 1px solid yellowgreen;
    }

    .cart-button {
      .circle-button {
        &.select {
          background-color: white;
          svg {
            color: $emphasis;

          }
        }
        background-color: yellowgreen;
        svg {
          color: purple;
        }
      }
    }

    &.select {
      border: 1px solid $emphasis;
      .product-image {
        outline: 1px solid $emphasis;
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  .product {
    width: 250px;
    height: 150px;
    gap: 4px;
    padding-right: 4px;

    .product-image {
      
    }
    .product-metadata {
      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
      }

      p {
        font-size: 12px;
        line-height: 12px;
      }
    }


  .cart-button {
    margin-right: 3px;
      .circle-button {
            width: 25px;
            height: 25px;
            svg {
                width: 12px;
                height: 12px;
                color: black;
            }
        }
    }

    .small-button {
      margin: 1em auto;
      width: 150px;
      height: 36px;
    }
  }
}


</style>
